import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { MentionItem, SuggestionDataItem } from 'react-mentions';
import styled, { css } from 'styled-components';

import { AnyPanelContext } from '../../../../entities/AssetManagement/global';
import { IMentionedItem } from '../../../../entities/IClusters';
import { IProfileType, IUserBrief } from '../../../../entities/IGlobal';
import { colorStack } from '../../../../styleHelpers/colors';
import { fontSize } from '../../../../styleHelpers/fontSizes';
import { getTimeFromNow } from '../../../../tools/date';
import { Avatar } from '../../Avatar/Avatar';
import { MentionInput } from '../../MentionInput/MentionInput';
import { RenderMentions } from '../../MentionInput/RenderMentions';
import { Spinner } from '../../Spinner/Spinner';
import { RichTextEditor } from '../../../Common/RichText/RichTextEditor';
import { RichTextView } from '../../../Common/RichText/RichTextView';
import { hasRichTextContent } from '../../../../tools/RichTextHelper';
import { KeyCode } from '../../../../tools/keyboardTools';

const Wrapper = styled.div`
    width: 310px;
    height: 100%;
    border-left: 2px solid ${colorStack.bodyBg};
    .mention-main-wrapper {
        height: 100%;
        > div {
            height: 100%;
        }
    }
`;

const CommentsBox = styled.div<{ withRT: boolean }>`
    background: ${colorStack.whiteGrey};
    height: calc(100% - 200px);
    display: flex;
    flex-direction: column;
    padding: 2rem .5rem 2rem 1rem;
    align-items: center;
    border-bottom: 2px solid ${colorStack.bodyBg};
    ${props => props.withRT && css`
        height: calc(100% - 200px);
    `}
`;

const AddCommentBox = styled.div<{ withRT: boolean }>`
    background: ${colorStack.white};
    padding: 0 0.5rem;
    height: 200px;
    > div {
        margin: 0;
        ${props => props.withRT && css`
            height: calc(200px - 1rem);
        `}
    }

`;

const NoMessages = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        margin-bottom: 30px;
    }
    span {
        font-size: ${fontSize[20]};
        color: ${colorStack.disabled};
        text-align: center;
    }
`;

const SingleMessage = styled.div`
    width: 100%;
    margin-bottom: 2rem;
`;

const Content = styled.div`
    margin-top: 1rem;
    span {
        word-break: break-word;
    }
`;

const MessagesBox = styled.div`
    display: flex;
    flex-direction: column-reverse;
`;

const Top = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
        width: calc(100% - 100px);
    }
    > span {
        color: ${colorStack.middleGrey};
    }
`;

const Input = styled.div<{disabled: boolean}>`
    display: flex;
    justify-content: space-between;
    height: 100%;
    padding: 1rem 0;
    svg {
        cursor: pointer;
        margin-top: 0.5rem;
        ${props => props.disabled && css`
            color: ${colorStack.disabled};
        `}
    }
`;

export interface ISingleFile {
    contentType: string;
    fileId: string;
    fileName: string;
    path: string;
}

export interface ICommentMessages {
    sender: IUserBrief;
    id: string;
    threadId: string;
    channelMessageId: string;
    createdDate: string;
    content?: string;
    files?: ISingleFile[];
    mentionedItems: IMentionedItem[];
}

interface IProps {
    context: React.Context<Partial<AnyPanelContext>>;
    messages: ICommentMessages[];
    withRT?: boolean;
    sendMessageHandler(message: string, mentions: MentionItem[]);
    getMentionSuggestions(query: string, callback: (data: SuggestionDataItem[]) => void);
}

export const PanelComments: FC<IProps> = props => {
    const refRichText = useRef<any>();
    const scrollBarsRef = useRef<Scrollbars>();
    const [message, setMessage] = useState<string>('');
    const [clearText, setClearText] = useState<boolean>(false);
    const [sendLoading, setSendLoading] = useState<boolean>(false);
    const [mentions, setMentions] = useState<MentionItem[]>([]);
    const [blockInput, setBlockInput] = useState<boolean>(false);

    useEffect(() => {
        scrollBarsRef?.current?.scrollToBottom();
    }, [props.messages]);

    useEffect(() => {
        if (blockInput) {
            sendMessageHandler();
        }
    }, [blockInput]);

    const sendMessageHandler = () => {
        if (message?.trim()?.length > 0) {
            setSendLoading(true);
            props.sendMessageHandler(message, mentions).then(() => {
                setMessage('');
                setClearText(true);
                setBlockInput(false);
                setSendLoading(false);
                scrollBarsRef?.current?.scrollToBottom();
            });
        }
    };

    useEffect(() => {
        setClearText(false);
    }, [clearText]);

    const changeMessage = (e: React.ChangeEvent<HTMLTextAreaElement>, newValue: string, newPlainTextValue: string, mentionsArr: MentionItem[]) => {
        setMentions(mentionsArr);
        setMessage(e.target.value);
    };

    const changeRTMessage = (data: string) => {
        setMessage(data);
    };

    const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.keyCode === KeyCode.Enter && message.trim().length === 0) {
            event.preventDefault();
        }
        if (event.keyCode === KeyCode.Enter && !blockInput && !event.shiftKey && message.trim().length > 0) {
            (event.target as HTMLElement).blur();
            setBlockInput(true);
            event.preventDefault();
            event.stopPropagation();
        }
    };

    return (
        <Wrapper>
            <CommentsBox withRT={props.withRT}>
                {props.messages?.length > 0 ? (
                    <Scrollbars ref={scrollBarsRef}>
                        <MessagesBox>
                            {props.messages.map(elem =>
                                <SingleMessage key={elem.id}>
                                    <Top>
                                        <Avatar
                                            id={elem.sender.id}
                                            firstName={elem.sender.firstName}
                                            lastName={elem.sender.lastName}
                                            picture={elem.sender.picture}
                                            extended
                                            type={IProfileType.Personal}
                                        />
                                        <span>{getTimeFromNow(elem.createdDate)}</span>
                                    </Top>
                                    <Content>
                                        {props.withRT ? (
                                            <RichTextView content={elem.content} />
                                        ) : (
                                            <RenderMentions
                                                content={elem.content}
                                            />
                                        )}
                                    </Content>
                                </SingleMessage>

                            )}
                        </MessagesBox>
                    </Scrollbars>
                ) : (
                    <NoMessages>
                        <img src={require('../../../../../wwwroot/assets/images/placeholders/message-placeholder.png')} />
                        <span>No discussion <br />started yet</span>
                    </NoMessages>
                )}
            </CommentsBox>
            <AddCommentBox withRT={props.withRT}>
                {props.withRT ? (
                    <RichTextEditor
                        ref={refRichText}
                        initData={message}
                        clearText={clearText}
                        onChanged={changeRTMessage}
                        sendLoading={sendLoading}
                        sendHandler={sendMessageHandler}
                        disableInitOnChange
                        buttons={{
                            boldButton: true,
                            italicButton: true,
                            underlineButton: true,
                            sendButton: true,
                            disabled: !hasRichTextContent(message)
                        }}
                    />
                ) : (
                    <Input disabled={message?.trim().length === 0}>
                        <MentionInput
                            value={message}
                            onChange={changeMessage}
                            data={props.getMentionSuggestions}
                            fontSize={fontSize[14]}
                            onKeyDown={onKeyDownHandler}
                            newDesign
                        />
                        {sendLoading ? (
                            <Spinner size="small" />
                        ) : (
                            <FontAwesomeIcon icon={faPaperPlane} onClick={sendMessageHandler} />
                        )}
                    </Input>
                )}
            </AddCommentBox>
        </Wrapper>
    );
};
