import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { MentionsInput, Mention, SuggestionDataItem, MentionItem, DataFunc } from 'react-mentions';
import { rgba } from 'polished';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';
import placeholder from '../../../styleHelpers/mixins/placeholder';
import { renderMentionSuggestion } from './Suggestions';

const ColorBar = styled.div<{mainContent: boolean}>`
    background-color: ${colorStack.white};
    ${props => props.mainContent && css`
        background-color: ${colorStack.whiteGrey};
    `}
    transition: background-color .3s;
    height: 2px;
    width: 100%;
    border-radius: 0 0 4px 4px;
`;

const Wrapper = styled.div<{ fontSize?: string, newDesign: boolean, topSuggestions: boolean }>`
    font-weight: normal;
    width: calc(100% - 25px);
    ${props => props.newDesign && css`
        height: 100%;
        padding: 10px 0 0 0;
    `}
    ${props => props.fontSize ? css`
        font-size: ${props.fontSize};
    ` : css`
        font-size:${fontSize[16]};
    `}
    &:hover ${ColorBar} {
        background-color: ${colorStack.middleBlue};
    }

    textarea {
        ${props => props.fontSize ? css`
            font-size: ${props.fontSize};
        ` : css`
            font-size: ${fontSize[16]};
        `}
        padding: 0;
        overflow: visible!important;
        border: 0;
        font-weight: normal;
        white-space: pre-wrap;
        ${placeholder({ 'color': colorStack.disabled })}
    }
    .mention-main-wrapper {
        ${props => props.topSuggestions && css`
            > div:nth-child(2) {
                top: auto!important;
                bottom: 110%;
            };
        `}
    }
`;

export interface IMentionInputProps {
    value: string;
    placeholderText?: string;
    data: SuggestionDataItem[] | DataFunc;
    fontSize?: string;
    newDesign?: boolean;
    mainContent?: boolean;
    className?: string;
    topSuggestions?: boolean;
    onKeyDown?(e: React.KeyboardEvent<HTMLInputElement>);
    onChange?(e: React.ChangeEvent<HTMLTextAreaElement>, newValue: string, newPlainTextValue: string, mentions: MentionItem[]);
}

export const MentionInput: FC<IMentionInputProps> = props => (
    <Wrapper fontSize={props.fontSize} className={props.className || ''} newDesign={props.newDesign} topSuggestions={props.topSuggestions}>
        <MentionsInput
            value={props.value}
            className="mention-main-wrapper"
            placeholder={props.placeholderText}
            onChange={props.onChange}
            onKeyDown={props.onKeyDown}
            style={{
                highlighter: {
                    color: 'transparent',
                    fontWeight: 'normal',
                    zIndex: 1,
                    pointerEvents: 'none'
                },
                input: {
                    top: '-3px'
                },
                suggestions: {
                    zIndex: 999,
                    left: '-4px',
                    top: '10%',
                    marginTop: '0',
                    width: '230px',
                    list: {
                        width: '230px',
                        boxShadow: `${rgba(colorStack.darkBlue, 0.1)} 0 0 6px 0`
                    }
                }
            }}
        >
            <Mention
                trigger="@"
                data={props.data}
                renderSuggestion={renderMentionSuggestion}
            />
        </MentionsInput>
        {props.newDesign &&
            <ColorBar mainContent={props.mainContent}/>
        }
    </Wrapper>
);
