import React, { ComponentClass, ComponentProps, ReactNode } from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';

import { NullPage } from './components/Home/NullPage';
import { AuthStart } from './components/Auth/AuthStart';
import { AuthEnd } from './components/Auth/AuthEnd';
import { Logout } from './components/Auth/Logout';
import { LogoutStart } from './components/Auth/LogoutStart';
import { LogoutEnd } from './components/Auth/LogoutEnd';
import RedirectTeams from './components/RedirectTeams';
import { TeamsAuth } from './components/TeamsAuth';
import { TeamsAuthStart } from './components/TeamsAuthStart';
import { TeamsAuthEnd } from './components/TeamsAuthEnd';
import store from './store';
import { getLoginPath, getContextUserId, isIdTokenExpired } from './tools/authTools';
import { history } from './history';
import { GlobalLoader } from './components/GlobalLoader';
import { PageNotFound } from './components/404';
import { instanceConfig } from './instance';
import Home from './routes/Home';
import GlobalDirectory from './routes/GlobalDirectory';
import Invite from './routes/Invite';
import RegisterContainerV2 from './routes/RegisterContainerV2';
import SettingsForm from './routes/SettingsForm';
import TermsAndConditionsContainer from './routes/TermsAndConditionsContainer';
import CaseCreationContainer from './routes/CaseCreationContainer';
import ValidationForm from './routes/ValidationForm';
import UserProfile from './routes/UserProfile';
import NotificationsContainer from './routes/NotificationsContainer';
import EvaluationConfirmationContainer from './routes/EvaluationConfirmationContainer';
import CopyrightsContainer from './routes/CopyrightsContainer';
import NetworkContainer from './routes/NetworkContainer';
import LegalEntitiesContainer from './routes/LegalEntitiesContainer';
import NewLegalEntity from './routes/NewLegalEntity';
import RfpInitialProporsal from './routes/RfpInitialProporsal';
import RfpSimpleComponent from './routes/RfpSimpleComponent';
import RfpFinancialsAndTimeline from './routes/RfpFinancialsAndTimeline';
import RfpRecipients from './routes/RfpRecipients';
import RfpSummary from './routes/RfpSummary';
import RfpExtras from './routes/RfpExtras';
import RfpSubmit from './routes/RfpSubmit';
import RfpWithdraw from './routes/RfpWithdraw';
import QuotationDetailsContainer from './routes/QuotationDetailsContainer';
import ProposalIntroduction from './routes/ProposalIntroduction';
import ProposalScopeTimeline from './routes/ProposalScopeTimeline';
import ProposalFinancials from './routes/ProposalFinancials';
import ProposalTeam from './routes/ProposalTeam';
import ProposalSummary from './routes/ProposalSummary';
import RFPRecipientSummary from './routes/RFPRecipientSummary';
import ProposalSumbission from './routes/ProposalSumbission';
import ManageMyProposals from './routes/ManageMyProposals';
import ProposalExtras from './routes/ProposalExtras';
import WithdrawProposal from './routes/WithdrawProposal';
import Messaging from './routes/Messaging';
import QuotationContainer from './routes/QuotationContainer';
import OrganizationSettings from './routes/OrganizationSettings';
import ClusterContainer from './routes/ClusterContainer';
import FastTrack from './routes/FastTrack';
import FastTrackSummary from './routes/FastTrackSummary';
import MyRequests from './routes/MyRequests';
import SingleStakeDataGrid from './routes/SingleStakeDataGrid';
import AdminConsoleContainer from './routes/AdminConsoleContainer';
import TeamSettings from './routes/TeamSettings';
import TeamsSettings from './routes/TeamsSettings';
import CreateTeam from './routes/CreateTeam';
import CodeOfConduct from './routes/CodeOfConduct';
import BookReferenceTexts from './routes/BookReferenceTexts';
import AllTexts from './routes/AllTexts';
import VersionOverview from './routes/VersionOverview';
import LegalDocument from './routes/LegalDocument';
import Panel from './routes/Panel';
import CLEContainer from './routes/CLEContainer';
import CLEGraphExport from './routes/CLEGraphExport';
import PLEPreviewExport from './routes/PLEPreviewExport';
import AddLawFirm from './routes/AddLawFirm';
import ViewLawFirm from './routes/ViewLawFirm';
import CodeArchives from './routes/CodeArchives';
import SogeDocumentation from './routes/SogeDocumentation';
import SogeNotices from './routes/SogeNotices';
import SogeArticle from './routes/SogeArticle';
import SogeVersions from './routes/SogeVersions';
import NormsManagement from './routes/NormsManagement';
import MoveContent from './routes/MoveContent';
import SettingsVersions from './routes/SettingsVersions';
import AmendementsVersions from './routes/AmendementsVersions';
import AmendmentsDatagrid from './routes/AmendmentsDatagrid';
import SogeBranches from './routes/SogeBranches';
import BranchesDatagrid from './routes/BranchesDatagrid';
import SogeApprovals from './routes/SogeApprovals';
import VersionDatagrid from './routes/VersionDatagrid';
import NewBranch from './routes/NewBranch';
import AuditTrail from './routes/AuditTrail';
import Directory from './routes/Directory';
import TeamsConfigCluster from './routes/TeamsConfigCluster';
import OrgsContainer from './routes/OrgsContainer';
import BranchCompare from './routes/BranchCompare';
import BranchArticleDetails from './routes/BranchArticleDetails';
import BranchStructureDetails from './routes/BranchStructureDetails';
import CreateAmendment from './routes/CreateAmendment';
import SogeSearch from './routes/SogeSearch';
import ManageBranchStructure from './routes/ManageBranchStructure';
import Reporting from './routes/Reporting';
import IconsIndex from './routes/IconsIndex';
import ComponentsIndex from './routes/ComponentsIndex';
import CampaignsList from './routes/CampaignsList';
import CampaignCreate from './routes/CampaignCreate';
import SingleCampaign from './routes/SingleCampaign';
import ClusterContent from './routes/ClusterContent';
import Registration from './routes/Registration';
import ResumeYourWorkContainer from './routes/ResumeYourWorkContainer';
import GloablLayout from './components/GlobalLayout';
import ViewNews from './routes/ViewNews';
import NewsList from './routes/NewsList';
import AddNewOrEditNews from './routes/AddNewOrEditNews';
import AMQuestions from './routes/AMQuestions';
import FAQDatagrid from './routes/FAQDatagrid';
import Billing from './routes/Billing';
import ClausierDatagrid from './routes/ClausierDataragid';
import OwnerDataTable from './routes/OwnerDataTable';
import RightsDatagrid from './routes/RightsDatagrid';

interface IRoute {
    exact?: boolean;
    path: string
    component: ReactNode | (<T extends RouteComponentProps>(props: T) => ReactNode);
    private?: boolean;
    ignoreInstanceConfig?: boolean;
    remountKey?: string;
    isShadowAuth?: boolean;
}

const PrivateRoute = ({ component: Component, dynamicRedirect: logicVar = false, isShadowAuth = false, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (store.getState().context.isLoggedIn || isShadowAuth) {
                return (<Component {...props} />);
            } else {
                !isShadowAuth && history.push(getLoginPath());
                return (<GlobalLoader isLoading />);
            }
        }} />
);

const appRoutes: IRoute[] = [
    { ignoreInstanceConfig: true, exact: true, path: '/', component: props => <Home {...props} isHomeRoute />, remountKey: 'home' },
    { ignoreInstanceConfig: true, exact: true, path: '/create-contract/:organization?', component: Home, remountKey: 'home' },
    { ignoreInstanceConfig: true, exact: true, path: '/create-gift/:organization?', component: Home, remountKey: 'home' },
    { ignoreInstanceConfig: true, exact: true, path: '/create-legal-support/:organization?', component: Home, remountKey: 'home' },
    { ignoreInstanceConfig: true, exact: true, path: '/create-lobbying/:organization?', component: Home, remountKey: 'home' },
    { ignoreInstanceConfig: true, exact: true, path: '/create-invitation/:organization?', component: Home, remountKey: 'home' },
    { ignoreInstanceConfig: true, exact: true, path: '/create-coi/:organization?', component: Home, remountKey: 'home' },
    { ignoreInstanceConfig: true, exact: true, path: '/create-sponsoring/:organization?', component: Home, remountKey: 'home' },
    { ignoreInstanceConfig: true, exact: true, path: '/null', component: NullPage },
    { private: true, path: '/invitations', component: Home },
    { private: true, path: '/news/edit/:id', component: AddNewOrEditNews },
    { private: true, path: '/news/new', component: AddNewOrEditNews },
    { private: true, path: '/news/:id', component: ViewNews },
    { private: true, path: '/news', component: NewsList },
    { private: true, path: '/manage-requests', component: Home },
    { private: true, path: '/manage-proposals', component: Home },
    { private: true, path: '/directory', component: GlobalDirectory },
    { private: true, path: '/profile/:id', component: UserProfile },
    { private: true, path: '/network', component: NetworkContainer },
    { private: true, path: '/case/create', component: CaseCreationContainer },
    { private: true, path: '/invite', component: Invite },
    { private: true, path: '/notifications', component: NotificationsContainer },
    { private: true, path: '/settings', component: SettingsForm },
    { private: true, path: '/billing', component: Billing },
    { path: '/terms', component: TermsAndConditionsContainer },
    { path: '/case/confirmation', component: EvaluationConfirmationContainer },
    { private: true, path: '/messaging', component: Messaging },
    { private: true, path: '/resume-your-work', component: ResumeYourWorkContainer },

    { private: true, exact: true, path: '/rfp', component: RfpInitialProporsal },
    { private: true, exact: true, path: '/rfp/description/:rfpId?', component: RfpInitialProporsal },
    { private: true, exact: true, path: '/rfp/new', component: RfpSimpleComponent },
    { private: true, exact: true, path: '/rfp/financials-timeline/:rfpId?', component: RfpFinancialsAndTimeline },
    { private: true, exact: true, path: '/rfp/recipients/:rfpId?', component: RfpRecipients },
    { private: true, exact: true, path: '/rfp/extras/:rfpId?', component: RfpExtras },
    { private: true, exact: true, path: '/rfp/summary/:rfpId?', component: RfpSummary },
    { private: true, exact: true, path: '/rfp/submit/:rfpId?', component: RfpSubmit },
    { private: true, path: '/rfp/withdraw/:rfpId?', component: RfpWithdraw },
    { private: true, path: '/rfp/complete/:rfpId?', component: RFPRecipientSummary },

    { private: true, path: '/proposal/introduction/:proposalId?', component: ProposalIntroduction },
    { private: true, path: '/proposal/scope-timeline/:proposalId?', component: ProposalScopeTimeline },
    { private: true, path: '/proposal/financials/:proposalId?', component: ProposalFinancials },
    { private: true, path: '/proposal/team/:proposalId?', component: ProposalTeam },
    { private: true, path: '/proposal/extras/:proposalId?', component: ProposalExtras },
    { private: true, path: '/proposal/summary/:proposalId?', component: ProposalSummary },
    { private: true, path: '/proposal/rfpSummary/:rfpId?', component: RFPRecipientSummary },
    { private: true, path: '/proposal/submit/:proposalId?', component: ProposalSumbission },
    { private: true, path: '/proposal/withdraw/:proposalId?', component: WithdrawProposal },

    { private: true, path: '/rfp/:rfpId?/proposals', component: ManageMyProposals },

    { path: '/copyrights', component: CopyrightsContainer },
    { path: '/register/:accountCreationKey?', component: RegisterContainerV2 },

    { path: '/companies', exact: true, component: LegalEntitiesContainer },
    { path: '/company/new', exact: true, component: NewLegalEntity },
    { path: '/company/:entityId', exact: true, component: LegalEntitiesContainer },
    { path: '/company/edit/:entityId', exact: true, component: NewLegalEntity },

    { private: true, path: '/orgs/:organization/dashboard', component: OrgsContainer },
    { private: true, path: '/orgs/:organization/questions', component: AMQuestions },
    { private: true, path: '/orgs/:organization/allFAQ', component: FAQDatagrid },
    { private: true, path: '/orgs/:organization/composite-side-letter', component: RightsDatagrid },
    { private: true, path: '/orgs/:organization/landlord-owner', component: OwnerDataTable },
    { private: true, path: '/orgs/:organization/clausier', component: ClausierDatagrid },
    { private: true, path: '/orgs/:organization/settings', component: OrganizationSettings },
    { private: true, exact: true, path: '/orgs/:organization/quotation', component: QuotationContainer },
    { private: true, exact: true, path: '/orgs/:organization/campaigns', component: CampaignsList },
    { private: true, exact: true, path: '/orgs/:organization/campaign/create', component: CampaignCreate },
    { private: true, exact: true, path: '/orgs/:organization/campaign/edit/:campaignId', component: CampaignCreate },
    { private: true, exact: true, path: '/orgs/:organization/campaign/:campaignId', component: SingleCampaign },
    { private: true, exact: true, path: '/orgs/:organization/quotation/:id', component: QuotationDetailsContainer },
    { private: true, exact: true, path: '/orgs/:organization/rfp/myrequests', component: MyRequests },
    { private: true, exact: true, path: '/orgs/:organization/rfp/fasttrack/:rfpId', component: FastTrack },
    { private: true, exact: true, path: '/orgs/:organization/rfp/fasttracksummary/:rfpId', component: FastTrackSummary },
    { private: true, exact: true, path: '/orgs/:organization/rfp/description/:rfpId', component: RfpInitialProporsal },
    { private: true, exact: true, path: '/orgs/:organization/rfp/financials-timeline/:rfpId', component: RfpFinancialsAndTimeline },
    { private: true, exact: true, path: '/orgs/:organization/rfp/recipients/:rfpId', component: RfpRecipients },
    { private: true, exact: true, path: '/orgs/:organization/rfp/extras/:rfpId', component: RfpExtras },
    { private: true, exact: true, path: '/orgs/:organization/rfp/summary/:rfpId', component: RfpSummary },
    { private: true, exact: true, path: '/orgs/:organization/directory', component: (props: ComponentProps<typeof Directory>) => <Directory {...props} /> },
    { private: true, exact: true, path: '/orgs/:organization/poa-holders', component: (props: ComponentProps<typeof Directory>) => <Directory {...props} poaHolders /> },
    { private: true, exact: true, path: '/orgs/:organization/clusters/stake-grid/:stakeId', component: SingleStakeDataGrid },
    { private: true, exact: true, path: '/orgs/:organization/clusters', component: ClusterContainer },
    { private: true, exact: true, path: '/orgs/:organization/clusters/:datagridKey', component: ClusterContainer },
    { private: true, exact: true, path: '/teams/config', component: TeamsConfigCluster },
    { private: true, exact: true, path: '/orgs/:organization/panel', component: Panel },
    { private: true, exact: true, path: '/orgs/:organization/entities/:tabName?', component: CLEContainer },
    { private: true, exact: true, path: '/orgs/:organization/panel/law-firm/new', component: AddLawFirm },
    { private: true, exact: true, path: '/orgs/:organization/panel/law-firm/:lawFirmId', component: ViewLawFirm },
    { private: true, exact: true, path: '/orgs/:organization/panel/law-firm/:lawFirmId/edit', component: AddLawFirm },
    { private: true, exact: true, path: '/orgs/:organization/cluster/:id', component: ClusterContent },
    { private: true, exact: true, path: '/orgs/:organization/cluster/:id/:page', component: ClusterContent },
    { private: true, exact: true, path: '/orgs/:organization/cluster/:id/:page/:messageId', component: ClusterContent },
    { private: true, exact: true, path: '/teams/orgs/:organization/cluster/:id', component: props => <ClusterContent {...props} isTeamsRoute /> },
    { private: true, exact: true, path: '/teams/orgs/:organization/cluster/:id/:page', component: props => <ClusterContent {...props} isTeamsRoute /> },
    { private: true, exact: true, path: '/teams/orgs/:organization/cluster/:id/:page/:messageId', component: props => <ClusterContent {...props} isTeamsRoute /> },

    { private: true, exact: true, path: '/admin-console/:teamId/settings', component: TeamSettings },
    { private: true, exact: true, path: '/admin-console/create-team', component: CreateTeam },
    { private: true, exact: true, path: '/admin-console/teams-settings', component: TeamsSettings },
    { private: true, exact: true, path: '/admin-console/:tab?', component: AdminConsoleContainer },

    { private: true, exact: true, path: '/orgs/:organization/documentation', component: SogeDocumentation },
    { private: true, exact: true, path: '/orgs/:organization/documentation/search/:versionId?', component: SogeSearch },
    { private: true, exact: true, path: '/orgs/:organization/documentation/manage-versions', component: SogeVersions },
    { private: true, exact: true, path: '/orgs/:organization/documentation/norms-management', component: NormsManagement },
    { private: true, exact: true, path: '/orgs/:organization/documentation/move-content', component: MoveContent },
    // { private: true, exact: true, path: '/orgs/:organization/documentation/new-version', component: NewDocumentVersion },
    { private: true, exact: true, path: '/orgs/:organization/documentation/versions/settings', component: SettingsVersions },
    { private: true, exact: true, path: '/orgs/:organization/documentation/versions/:versionId/amendements', component: AmendementsVersions },
    { private: true, exact: true, path: '/orgs/:organization/documentation/versions/:versionId/amendements/datagrid', component: AmendmentsDatagrid },
    { private: true, exact: true, path: '/orgs/:organization/documentation/versions/new-amendement/:step?', component: CreateAmendment, remountKey: 'amendment' },
    { private: true, exact: true, path: '/orgs/:organization/documentation/versions/new-amendement/:step?/:amendmentId?', component: CreateAmendment, remountKey: 'amendment' },
    { private: true, exact: true, path: '/orgs/:organization/documentation/versions/:versionId', component: VersionOverview, remountKey: 'version-overview' },
    { private: true, exact: true, path: '/orgs/:organization/documentation/versions/:versionId/structure/:structureId/:articleId?', component: VersionOverview, remountKey: 'version-overview' },
    { private: true, exact: true, path: '/orgs/:organization/documentation/versions/:versionId/branches', component: SogeBranches },
    { private: true, exact: true, path: '/orgs/:organization/documentation/versions/:versionId/branches/datagrid', component: BranchesDatagrid },
    { private: true, exact: true, path: '/orgs/:organization/documentation/versions/:versionId/branches/:documentElementCommonId/request-approval', component: (props: ComponentProps<typeof BranchCompare>) => <BranchCompare {...props} requestApprovalMode />, remountKey: 'branch-compare' },
    { private: true, exact: true, path: '/orgs/:organization/documentation/versions/:versionId/branches/:documentElementCommonId/approve/:requestId', component: (props: ComponentProps<typeof BranchCompare>) => <BranchCompare {...props} approveMode />, remountKey: 'branch-compare' },
    { private: true, exact: true, path: '/orgs/:organization/documentation/versions/:versionId/branches/:documentElementCommonId/:branchId', component: BranchCompare, remountKey: 'branch-compare' },
    { private: true, exact: true, path: '/orgs/:organization/documentation/versions/:versionId/branches/:documentElementCommonId/:branchId/article/:articleId', component: BranchArticleDetails },
    { private: true, exact: true, path: '/orgs/:organization/documentation/versions/:versionId/branches/:documentElementCommonId/:branchId/structure-level/:structureId', component: BranchStructureDetails },
    { private: true, exact: true, path: '/orgs/:organization/documentation/versions/:versionId/branches/:documentElementCommonId/:branchId/manage-structure', component: ManageBranchStructure },
    { private: true, exact: true, path: '/orgs/:organization/documentation/versions/:versionId/branches/:documentElementCommonId/:branchId/audit-trail', component: AuditTrail },
    { private: true, exact: true, path: '/orgs/:organization/documentation/versions/:versionId/approvals', component: SogeApprovals },
    { private: true, exact: true, path: '/orgs/:organization/documentation/versions/:versionId/datagrid', component: VersionDatagrid },
    { private: true, exact: true, path: '/orgs/:organization/documentation/versions/:versionId/new-branch', component: NewBranch },
    { private: true, exact: true, path: '/orgs/:organization/documentation/collections/:versionId?', component: CodeOfConduct, remountKey: 'sg-consultation' },
    { private: true, exact: true, path: '/orgs/:organization/documentation/collections/:versionId/:commonLevelId/texts', component: BookReferenceTexts, remountKey: 'sg-consultation' },
    { private: true, exact: true, path: '/orgs/:organization/documentation/texts/:versionId?', component: AllTexts, remountKey: 'sg-consultation' },
    { private: true, exact: true, path: '/orgs/:organization/documentation/archives', component: CodeArchives },
    { private: true, exact: true, path: '/orgs/:organization/documentation/avis', component: SogeNotices },
    { private: true, exact: true, path: '/orgs/:organization/documentation/reporting', component: Reporting },
    { private: true, exact: true, path: '/orgs/:organization/documentation/:structureId/:versionId/article/:articleId', component: SogeArticle },
    { private: true, exact: true, path: '/orgs/:organization/documentation/:structureId', component: LegalDocument, remountKey: 'structure-level' },
    { private: true, exact: true, path: '/orgs/:organization/documentation/:structureId/:versionId', component: LegalDocument, remountKey: 'structure-level' },
    { private: true, exact: true, path: '/dev/icons', component: IconsIndex },
    { private: true, exact: true, path: '/dev/components', component: ComponentsIndex },
    { private: true, path: '/registration-final-step', component: Registration }
];

const defaultContainer = () => (
    <GloablLayout>
        <Switch>
            {appRoutes.map(appRoute => appRoute.ignoreInstanceConfig || (instanceConfig.routes || []).indexOf(appRoute.path) !== -1
                ? appRoute.private
                    ? <PrivateRoute key={appRoute.remountKey || appRoute.path} exact={appRoute.exact} path={appRoute.path} component={appRoute.component} isShadowAuth={store.getState().context.isShadowAuth} />
                    : <Route key={appRoute.remountKey || appRoute.path} exact={appRoute.exact} path={appRoute.path} render={routeProps => {
                        if (appRoute.path !== '/' && !store.getState().context.isLoggedIn && getContextUserId() &&  !isIdTokenExpired(getContextUserId())) {
                            history.push(getLoginPath());
                            return (<GlobalLoader isLoading />);
                        }
                        const Component = appRoute.component as ComponentClass<RouteComponentProps>;
                        return <Component  {...routeProps} />;
                    }} />
                : () => <PageNotFound key={appRoute.path} />
            )}
        </Switch>
    </GloablLayout>
);

export const routes = (
    <Switch>
        <Route path="/case/evaluate/validation" component={(instanceConfig.routes || []).indexOf('/case/evaluate/validation') === -1
            ? PageNotFound
            : ValidationForm
        } />
        <Route path="/login" component={AuthStart} />
        <Route path="/logout" component={Logout} />
        <Route path="/auth-callback" component={AuthEnd} />
        <Route path="/reset-password" component={() => <AuthStart passwordResetMode />} />
        {IS_TEAMS_INSTANCE && (
            <Route path="/redirect-teams" component={RedirectTeams} />
        )}
        {IS_TEAMS_INSTANCE && (
            <Route path="/teamsauth" component={TeamsAuth} />
        )}
        {IS_TEAMS_INSTANCE && (
            <Route path="/teams/auth-start" component={TeamsAuthStart} />
        )}
        {IS_TEAMS_INSTANCE && (
            <Route path="/teams/auth-end" component={TeamsAuthEnd} />
        )}
        {IS_TEAMS_INSTANCE && (
            <Route path="/teams/logout-start" component={LogoutStart} />
        )}
        {IS_TEAMS_INSTANCE && (
            <Route path="/teams/logout-end" component={LogoutEnd} />
        )}

        <Route exact path="/orgs/:organizationId/entities/export-graph" component={CLEGraphExport} />
        <Route exact path="/company/:entityId/export-entity" component={PLEPreviewExport} />
        <Route component={defaultContainer} />
        <Route component={() => <PageNotFound />} />
    </Switch>
);
