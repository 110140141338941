import React, { FC, useCallback, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ApproverBox, AvatarBox, LeftBar, Status, ButtonBox, StageNumber, Label, ApprovedInformations, RoleContainer } from './StyledComponents';
import { Avatar } from '../../../../Common/Avatar/Avatar';
import { Button } from '../../../../Common/Buttons/Button';
import { ValidationResponse, ISingleValidator, StatusesTypes } from '../../../../../entities/IValidation';
import { changeValidationStatus } from '../../../../../actions/validationActions';
import { getSingleCluster } from '../../../../../actions/clustersActions';
import { IState } from '../../../../../reducers';
import { IProfileReducer } from '../../../../../reducers/profileReducer';
import { useAlert } from '../../../../../tools/hooks';
import { AlertType } from '../../../../../entities/IAlert';
import { getReferentials } from '../../../../../actions/globalActions';
import { Referentials, IContextList, IReferencial, EValidationRequestStageStatus, EValidationRequestStatus, EValidatorStatus } from '../../../../../entities/IGlobal';
import { ConfirmPopup } from '../../../../Common/ConfirmPopup/ConfirmPopup';
import { getProfileById } from '../../../../../actions/profileActions';
import { IFullUserProfile } from '../../../../../entities/IProfile';
import { colorStack } from '../../../../../styleHelpers/colors';
import { getStatusText } from '../../../../../tools/statusHelper';

type GetReferentials = ReturnType<typeof getReferentials>;
type ChangeValidationStatus = ReturnType<typeof changeValidationStatus>;
type GetSingleCluster = ReturnType<typeof getSingleCluster>;
type GetProfileById = ReturnType<typeof getProfileById>;

interface IProps {
    validator: ISingleValidator;
    message: ValidationResponse;
    currentClusterId: string;
    validationId: string;
    stage: number;
    isActive?: boolean;
    closePopup?(moreDetails: boolean);
    openPanel?(showPanel: boolean);
    getTemplatesSettingsAndData?();
}

export const SingleApprover: FC<IProps> = props => {
    const addAlert = useAlert();
    const intl = useIntl();
    const dispatch = useDispatch();
    const { id } = useParams<{ organization: string; id: string }>();
    const { currentUserProfile } = useSelector<IState, IProfileReducer>(state => state.profile);
    const [disableButtons, setDisableButtons] = useState<boolean>(false);
    const [confirmPopupOn, setConfirmPopupOn] = useState<boolean>(false);
    const [roles, setRoles] = useState<IReferencial[]>(undefined);
    const [activeStatus, setActiveStatus] = useState<StatusesTypes>(undefined);
    const [requestedUserData, setRequestedUserData] = useState<IFullUserProfile>(undefined);
    const [confirmPopupLabel, setConfirmPopupLabel] = useState<string>('');
    const [validatorProfile, setValidatorProfile] = useState<IFullUserProfile>(undefined);

    useEffect(() => {
        dispatch<GetReferentials>(getReferentials('', Referentials.ApprovalLabel, IContextList.Platform)).then(response => {
            setRoles(response);
        });
        dispatch<GetProfileById>(getProfileById(props.validator?.principal?.childId)).then(res => {
            setRequestedUserData(res);
        });
    }, []);

    const setConfirmPopup = (e?: React.MouseEvent<HTMLDivElement>) => {
        e?.stopPropagation();
        setConfirmPopupOn((prevConfirmPopupOn: boolean) => !prevConfirmPopupOn);
    };

    const changeStatus = useCallback((status: StatusesTypes) => {
        const onBehalfUserId = props.validator?.principal?.childId !== currentUserProfile.id ? props.validator?.principal?.childId : undefined;
        setDisableButtons(true);
        dispatch<ChangeValidationStatus>(changeValidationStatus(props.currentClusterId, props.validationId, status, onBehalfUserId)).then(() => {
            if (status === StatusesTypes.Acknowledged) {
                addAlert(<FormattedMessage id="validation.alert.acknowledged" />, AlertType.Success);
            }
            if (status === StatusesTypes.Opinion) {
                addAlert(<FormattedMessage id="validation.alert.opinion" />, AlertType.Success);
            }
            if (status === StatusesTypes.Approved) {
                addAlert(<FormattedMessage id="validation.alert.approved" />, AlertType.Success);
            }
            if (status === StatusesTypes.Rejected) {
                addAlert(<FormattedMessage id="validation.alert.reject" />, AlertType.Success);
            }
            if (status === StatusesTypes.Favorable) {
                addAlert(<FormattedMessage id="validation.alert.favorable" />, AlertType.Success);
            }
            if (status === StatusesTypes.Unfavorable) {
                addAlert(<FormattedMessage id="validation.alert.unfavorable" />, AlertType.Success);
            }
            if (status === StatusesTypes.Approval) {
                addAlert(<FormattedMessage id="validation.alert.approval" />, AlertType.Success);
            }
            setDisableButtons(false);
            props.closePopup && props.closePopup(false);
            props.openPanel && props.openPanel(true);
            dispatch<GetSingleCluster>(getSingleCluster(id));
            props.getTemplatesSettingsAndData && props.getTemplatesSettingsAndData();
        });
    }, [props.validationId, props.currentClusterId, props.validator?.principal?.childId]);

    const approvalActionHandler = useCallback((status: StatusesTypes, confirmPopupLabelId: string) => {
        const onBehalfUserId = props.validator?.principal?.childId !== currentUserProfile.id;
        setActiveStatus(status);
        if (onBehalfUserId) {
            setConfirmPopupLabel(confirmPopupLabelId);
            return setConfirmPopup();
        }
        changeStatus(status);
    }, [props.validator?.principal?.childId]);

    useEffect(() => {
        let validStates;
        props.message?.stages?.forEach((stage) => {
            if (stage?.status as unknown as EValidationRequestStageStatus === EValidationRequestStageStatus.Rejected) validStates = true;
        });
        setDisableButtons(validStates || props.message?.status === EValidationRequestStatus.Rejected || props.message?.status === EValidationRequestStatus.Disabled);
    }, [props.message]);

    useEffect(() => {
        if (!props.validator?.modifiedBy?.firstName && props?.validator?.modifiedBy?.id) {
            dispatch<GetProfileById>(getProfileById(props?.validator?.modifiedBy?.id)).then(res => {
                setValidatorProfile(res);
            });
        }
    }, [props.validator]);

    return (
        <ApproverBox key={props.validator?.principal?.childId}>
            <AvatarBox>
                <LeftBar status={props.validator?.status} />
                <StageNumber>{props.stage + 1}</StageNumber>
                <div>
                    <ApprovedInformations isJobTitle={!!requestedUserData?.jobTitle}>
                        <Avatar
                            picture={props.validator?.principal?.picture}
                            firstName={props.validator?.principal?.childName}
                            lastName=""
                            id={props.validator?.principal?.childId}
                            extended
                            size={{
                                width: 160,
                                height: 32,
                                onlyWrapperSize: true
                            }}
                            labelSize={16}
                        />
                    </ApprovedInformations>
                    {requestedUserData?.jobTitle && <RoleContainer>{requestedUserData?.jobTitle}</RoleContainer>}
                </div>
            </AvatarBox>
            <Label>
                <span>{roles?.find(el => el.Key === props.validator.role?.Key)?.name}</span>
            </Label>
            <Label>
                <FormattedMessage id={`approval.validator.action.${props.validator?.validatorType}`} />
            </Label>
            <Status status={props.validator?.status || props.message.stages?.[props.stage]?.status || props.message?.status} square>
                {getStatusText(props.validator?.status || props.message.stages?.[props.stage]?.status || props.message?.status, intl)}
            </Status>
            <ButtonBox isButtonsView={!(props.validator?.status !== EValidatorStatus.InProgress && (!!props.validator?.modifiedAt))}>
                {(props.validator?.validatorType === 'Acknowledgement' && !props.validator?.modifiedAt && props.validator?.status !== EValidatorStatus.Acknowledged) &&
                    <Button bgColor={colorStack.white} borderColor={colorStack.middleGrey} fontColor={colorStack.darkGrey} data-lc="js-lc-button-approver-acknowledgement" onClick={() => approvalActionHandler(StatusesTypes.Acknowledged, 'approval.confirmPopup.label.acknowledged')} disabled={disableButtons || !props.isActive}>
                        <FormattedMessage id="approval.validatorType.button.acknowledgement" />
                    </Button>
                }
                {(props.validator?.validatorType === 'Opinion' && !props.validator?.modifiedAt && (props.validator?.status !== EValidatorStatus.Unfavorable && props.validator?.status !== EValidatorStatus.Favorable)) &&
                    <>
                        <Button bgColor={colorStack.white} borderColor={colorStack.middleGrey} fontColor={colorStack.darkGrey} data-lc="js-lc-button-approver-unfavorable" onClick={() => approvalActionHandler(StatusesTypes.Unfavorable, 'approval.confirmPopup.label.unfavorable')} disabled={disableButtons || !props.isActive}>
                            <FormattedMessage id="approval.validatorType.unfavorable" />
                        </Button>
                        <Button bgColor={colorStack.white} borderColor={colorStack.middleGrey} fontColor={colorStack.darkGrey} onClick={() => approvalActionHandler(StatusesTypes.Favorable, 'approval.confirmPopup.label.favorable')} data-lc="js-lc-button-approver-favorable" disabled={disableButtons || !props.isActive}>
                            <FormattedMessage id="approval.validatorType.favorable" />
                        </Button>
                    </>
                }
                {(props.validator?.validatorType === 'Approval' && !props.validator?.modifiedAt && (props.validator?.status !== EValidatorStatus.Approved && props.validator?.status !== EValidatorStatus.Rejected)) &&
                    <>
                        <Button bgColor={colorStack.white} borderColor={colorStack.middleGrey} fontColor={colorStack.darkGrey} data-lc="js-lc-button-approver-reject" onClick={() => approvalActionHandler(StatusesTypes.Rejected, 'approval.confirmPopup.label.reject')} disabled={disableButtons || !props.isActive}>
                            <FormattedMessage id="global.reject" />
                        </Button>
                        <Button bgColor={colorStack.white} borderColor={colorStack.middleGrey} fontColor={colorStack.darkGrey} onClick={() => approvalActionHandler(StatusesTypes.Approved, 'approval.confirmPopup.label.approve')} data-lc="js-lc-button-approver-approve" disabled={disableButtons || !props.isActive}>
                            <FormattedMessage id="global.approve" />
                        </Button>
                    </>
                }
                {(props.validator?.status !== EValidatorStatus.InProgress && (!!props.validator?.modifiedAt)) &&
                    <div>
                        <ApprovedInformations isJobTitle={!!props.validator?.modifiedBy?.jobTitle || !!validatorProfile?.jobTitle}>
                            <Avatar
                                picture={props.validator?.modifiedBy?.picture || validatorProfile?.picture}
                                firstName={props.validator?.modifiedBy?.firstName || validatorProfile?.firstName}
                                lastName={props.validator?.modifiedBy?.lastName || validatorProfile?.lastName}
                                id={props.validator?.modifiedBy?.id}
                                extended
                                size={{
                                    width: 160,
                                    height: 32,
                                    onlyWrapperSize: true
                                }}
                                labelSize={16}
                            />
                        </ApprovedInformations>
                        {(props.validator?.modifiedBy?.jobTitle || validatorProfile?.jobTitle) && <RoleContainer>{props.validator?.modifiedBy?.jobTitle || validatorProfile?.jobTitle}</RoleContainer>}
                            <RoleContainer>
                                <FormattedMessage id="validation.approval.date" />
                                <span>{moment(props.validator?.modifiedAt).format('DD MMM YYYY')}</span>
                            </RoleContainer>
                    </div>
                }
            </ButtonBox>
            <ConfirmPopup
                showPopup={confirmPopupOn}
                yesHandler={() => changeStatus(activeStatus)}
                noHandler={setConfirmPopup}
                loading={disableButtons}
                noButtonText={<FormattedMessage id="global.no" />}
                yesButtonText={<FormattedMessage id="global.yes" />}
            >
                <FormattedMessage id={confirmPopupLabel} values={{name: props.validator?.principal?.childName}} />
            </ConfirmPopup>
        </ApproverBox>

    );
};