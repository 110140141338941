import { Dispatch } from 'redux';

import { IActivitySearch } from '../entities/Activity/global';
import { IActivityFilters } from '../modules/Activity';
import { IState } from '../reducers';
import { Http } from '../tools/http';
import { action } from '../tools/reduxTools';

export const getActivityChannelMessages = (page: number, size: number, resourceChildId: string, teamId: string, channelResourceId?: string, facets?: IActivityFilters) => action<Promise<IActivitySearch>>((dispatch: Dispatch, getState: () => IState, http: Http) => {
    const { clusters } = getState();
    return http.messagingApiPost(`/resources/${resourceChildId}/teams/${teamId}/channels/${channelResourceId || clusters.currentChannelId}/messages/search`, facets || {});
});