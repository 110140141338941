import React, { FC, useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Scrollbars from 'react-custom-scrollbars';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MentionItem, SuggestionDataItem } from 'react-mentions';

import { ApprovalDetails } from './ApprovalDetails';
import { IApprovalPanelContext } from '../../entities/IValidation';
import { ICommentMessages, PanelComments } from '../Common/Panel/PanelComments/PanelComments';
import { IActivitySearchItem } from '../../entities/Activity/global';
import { getClusterMembers, getTeamsWithChannels, sendMessage } from '../../actions/clustersActions';
import { MentionType, SendMessageBody } from '../../entities/IClusters';
import { EActivityTypes } from '../../entities/Activity/enums';

type GetClusterMembers = ReturnType<typeof getClusterMembers>;
type SendMessage = ReturnType<typeof sendMessage>;
type GetTeamsWithChannels = ReturnType<typeof getTeamsWithChannels>;

const ApprovalWrapper = styled.div<{showComment: boolean}>`
    padding: 1rem 4rem;
    height: 100%;
    width: 100%;
    ${props => props.showComment && css`
        width: calc(100% - 310px);
    `}
`;
const Wrapper = styled.div`
    height: 100%;
    display: flex;
`;

interface IProps {
    context: React.Context<Partial<IApprovalPanelContext>>;
    message?: IActivitySearchItem;
}

export const ApprovalPanelContent: FC<IProps> = props => {
    const panelContext = useContext<Partial<IApprovalPanelContext>>(props.context);
    const { id } = useParams<{ organization: string, id: string }>();
    const [messages, setMessages] = useState<ICommentMessages[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        setMessages(props.message?.threadMessages.map(elem => ({
            id: elem.channelMessageId,
            sender: elem.creator,
            channelMessageId: elem.channelMessageId,
            threadId: elem.threadId,
            createdDate: elem.createdAt,
            content: elem.details.content,
            mentionedItems: []
        })));
    }, [props.message?.threadMessages]);

    useEffect(() => {
        panelContext?.setShowComments && panelContext?.setShowComments(props.message?.type === EActivityTypes.Text);
    }, []);

    const sendMessageHandler = (message: string, mentions: MentionItem[]) => {
        const body: SendMessageBody = {
            content: message.trim(),
            type: 'Text',
            tempId: (props.message?.threadMessages?.length + 1)?.toString() || '0',
            threadId: props.message?.threadId,
            mentionedItems: (mentions || []).map((mention) => {
                return {
                    text: mention.display,
                    linkedId: mention.id,
                    type: MentionType.User
                };
            })
        };
        return new Promise(resolve => {
            dispatch<GetTeamsWithChannels>(getTeamsWithChannels(id)).then(res => {
                dispatch<SendMessage>(sendMessage(body, res?.[0].teamChannels?.[0].teamChannelResourceId, res?.[0].teamChannels?.[0].teamId)).then((response) => {
                    const newElem = {
                        id: response.id,
                        sender: response.sender,
                        createdDate: response.createdDate,
                        content: response.content,
                        channelMessageId: response.channelMessageId,
                        threadId: response.threadId,
                        mentionedItems: response.mentionedItems
                    };
                    setMessages(state => state ? [newElem, ...state] : [newElem]);
                    resolve(true);
                });
            });
        });

    };

    const getMentionSuggestions = (query: string, callback: (data: SuggestionDataItem[]) => void) => {
        dispatch<GetClusterMembers>(getClusterMembers(id, query)).then(members => {
            callback([
                ...members?.map(member => ({
                    id: member.childId || member.id,
                    display: !!member?.childName ? member?.childName : `${member?.firstName} ${member?.lastName}`,
                    picture: member.picture
                }))
            ]);
        });
    };

    return (
        <Wrapper>
            <ApprovalWrapper showComment={panelContext?.showComments}>
                <Scrollbars>
                    <ApprovalDetails
                        validationId={panelContext.validationId}
                        clusterId={panelContext.clusterId}
                        setShowPopup={panelContext.setShowPanel}
                        showPopup={panelContext.showPanel}
                        getTemplatesSettingsAndData={panelContext?.getTemplatesSettingsAndData}
                        setStatus={panelContext.setStatus}
                        threadId={props.message?.threadId}

                    />
                </Scrollbars>
            </ApprovalWrapper>
            {panelContext?.showComments &&
                <PanelComments
                    context={props.context}
                    sendMessageHandler={sendMessageHandler}
                    getMentionSuggestions={getMentionSuggestions}
                    messages={messages}
                />
            }
        </Wrapper>
    );
};