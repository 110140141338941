import { rgba } from 'polished';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';

import { ConfirmPopup } from '../ConfirmPopup/ConfirmPopup';
import { colorStack } from '../../../styleHelpers/colors';
import { Spinner } from '../Spinner/Spinner';

const Blend = styled.div<{ showPanel: boolean }>`
    transition: background-color .3s;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    ${props => props.showPanel && css`
        background-color: ${rgba(colorStack.black, .3)};
    `}
    ${props => !props.showPanel && css`
        background-color: ${rgba(colorStack.black, 0)};
    `}
`;

const Wrapper = styled.div<{ showPanel: boolean }>`
    @keyframes rightAnimaPanel {
        from {
            right: 0;
        }
        to {
            right: -100%;
        }
    }
    width: calc(100% - 69px);
    height: calc(100vh - 48px);
    position: fixed;
    display: flex;
    justify-content: right;
    top: 49px;
    z-index: 999;
    ${props => props.showPanel && css`
        right: 0;
    `}
    ${props => !props.showPanel && css`
        animation-name: rightAnimaPanel;
        animation-delay: 1s;
        animation-fill-mode: forwards;
    `}
`;

const PanelContent = styled.div<{ showPanel: boolean }>`
    width: 75%;
    height: 100%;
    right: -100%;
    position: absolute;
    transition: right .3s;
    ${props => props.showPanel && css`
        right: 0;
    `}
`;

const Header = styled.div`
    background: ${colorStack.white};
    height: 75px;
    display: flex;
    align-items: center;
`;

const SpinnerBox = styled.div`
    width: 38px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Content = styled.div`
    background: ${colorStack.white};
    height: calc(100% - 75px);
`;

const HeaderContent = styled.div`
    width: calc(100% - 38px);
    height: 100%;
`;

const CloseBox = styled.div`
    width: 38px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    > svg {
        width: 38px;
        height: 30px;
    }
`;

interface IProps<T> {
    headerContent?: React.ReactNode;
    children: React.ReactNode;
    context: React.Context<Partial<T>>;
}

export const Panel = <T extends object = {}>(props: IProps<T>) => {
    const panelContext = useContext<Partial<T|any>>(props.context);
    // tslint:disable-next-line:no-null-keyword
    const wrapperRef = useRef(null);
    const [leaving, setLeaving] = useState<boolean>(false);
    const [closingFromBlendHandler, setClosingFromBlendHandler] = useState<boolean>(false);

    useEffect(() => {
        if (leaving && !panelContext.unsavedChanges) {
            leavePanel();
        }
    }, [leaving, panelContext.unsavedChanges]);

    const stopLeaving = () => {
        setLeaving(false);
    };

    const leavePanel = () => {
        setLeaving(false);
        panelContext.setUnsavedChanges?.(false);

        if (closingFromBlendHandler) {
            panelContext.setShowPanel(false);

        } else {
            setTimeout(() => {
                if (panelContext.setLoading) {
                    panelContext.setLoading(!panelContext.loading);
                } else {
                    panelContext.setShowPanel(!panelContext.showPanel);
                }
            }, 300);
        }
    };

    const leaveWithoutSaving = () => {
        leavePanel();
    };

    const closeHandler = () => {
        setClosingFromBlendHandler(false);
        setLeaving(true);
    };

    const blendHandler = () => {
        setClosingFromBlendHandler(true);
        setLeaving(true);
    };

    return (
        <Wrapper showPanel={panelContext.showPanel}>
            <Blend showPanel={panelContext.showPanel} onClick={blendHandler}/>
            <PanelContent showPanel={panelContext.showPanel} ref={wrapperRef}>
                <Header>
                    {panelContext.loading ? (
                        <SpinnerBox>
                            <Spinner size="medium" />
                        </SpinnerBox>
                    ) : (
                        <CloseBox onClick={closeHandler}>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </CloseBox>
                    )}
                    {(props.headerContent && panelContext.showPanel) &&
                        <HeaderContent>
                            {props.headerContent}
                        </HeaderContent>
                    }
                </Header>
                <Content>
                    {panelContext.showPanel && props.children}
                </Content>
            </PanelContent>
            <ConfirmPopup
                showPopup={leaving && panelContext.setUnsavedChanges && panelContext.unsavedChanges}
                yesHandler={leaveWithoutSaving}
                noHandler={stopLeaving}>
                <FormattedMessage id="assetmanagement.confirm.unsavedChanges" />
            </ConfirmPopup>
        </Wrapper>
    );
};
