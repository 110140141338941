import React, { FC } from 'react';
import styled from 'styled-components';

import { linkify } from '../../../tools/string';
import { MultilineTextWithEllipsis } from '../TextWithEllipsis/MultilineTextWithEllipsis';

const Content = styled.span`
    a {
        font-weight: 600;
    }
`;

interface IRenderMentionsProps {
    content: string;
    lines?: number;
    tooltip?: boolean;
}

export const RenderMentions: FC<IRenderMentionsProps> = props => {
    const regex = /@\[(.*?)\]\(([a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12})\)/gm;
    const regexGroups = props.content?.match(regex) || [];
    let finalContent = props.content;
    for (let i = 0; i < regexGroups.length; i++) {
        const name = regexGroups[i].match(/(?<=@\[).*(?=\]\()/gm)[0];
        const id = regexGroups[i].match(/(?<=\]\().*(?=\))/gm)[0];
        finalContent = finalContent.replace(regexGroups[i], `<a href=${`/profile/${id}`} target="__blank">${name}</a>`);
    }
    return (
        <>
            {props.lines ?
                <MultilineTextWithEllipsis numberOfLines={props.lines} noTooltip={!props.tooltip}>
                    <Content dangerouslySetInnerHTML={{ __html: linkify(finalContent) }} />
                </MultilineTextWithEllipsis>
            : <Content dangerouslySetInnerHTML={{ __html: linkify(finalContent) }} />}
        </>
    );
};