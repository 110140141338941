import React, { FC, useCallback, useState, useMemo, useEffect, Fragment, createContext } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import { IApprovalPanelContext, ValidationResponse } from '../../../../../entities/IValidation';
import { ISingleChannelMessage } from '../../../../../entities/IClusters';
import { colorStack } from '../../../../../styleHelpers/colors';
import { Avatar } from '../../../../Common/Avatar/Avatar';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { ApprovalStamp } from '../../../../Common/Icons';
import { SingleMessageContent } from './Content';
import { IState } from '../../../../../reducers';
import { IClusterReducer } from '../../../../../reducers/clustersReducer';
import { IProfileReducer } from '../../../../../reducers/profileReducer';
import { SingleMessageHeader } from './Header';
import { getSingleValidation } from '../../../../../actions/validationActions';
import { PathType } from '../../../../Approvals/ApprovalDetails';
import { ScopeName, Status, AttachmentBox } from './StyledComponents';
import { SingleApprover } from './SingleApprover';
import { Attachment } from '../../../../Common/Attachment/Attachment';
import { Panel } from '../../../../Common/Panel/Panel';
import { ApprovalPanelHeader } from '../../../../Approvals/ApprovalPanelHeader';
import { ApprovalPanelContent } from '../../../../Approvals/ApprovalPanelContent';
import { history } from '../../../../../history';
import { EValidationRequestStageStatus, EValidationRequestStatus } from '../../../../../entities/IGlobal';
import { getStatusText } from '../../../../../tools/statusHelper';

type GetSingleValidation = ReturnType<typeof getSingleValidation>;
const Wrapper = styled.div`
`;

const Header = styled.div`
    background: ${colorStack.whiteGrey};
    padding: .5rem 1rem;
    display: flex;
    justify-content: space-between;
`;

const Title = styled.div`
    color: ${colorStack.blue};
    font-size: ${fontSize[16]};
    font-weight: 500;
    margin: 0 1rem 0 0;
`;

const DateContainer = styled.div`
    border-left: 1px solid ${colorStack.darkGrey};
    padding: 0 0 0 1rem;
    color: ${colorStack.darkGrey};
    font-size: ${fontSize[10]};
    margin: 0 0 0 1rem;
`;

const Left = styled.div`
    display: flex;
    align-items: center;
`;
const Right = styled.div`
    display: flex;
`;

const Content = styled.div`
    background: ${colorStack.whiteGrey};
    margin: 0 0 2px 0;
`;

export const StampIco = styled.div`
    margin: 0 1rem 0 0;
`;

export const MoreDetails = styled.button`
    margin: 1%;
    padding: 0.5rem 2rem;
    border: 1px solid ${colorStack.middleBlue};
    border-radius: 4px;
    cursor: pointer;
    color: ${colorStack.middleBlue};
    font-weight: 400;
`;

const ThreadMessages = styled.div`
    background: ${colorStack.white};
`;

const Messgaes = styled.div`
    padding: .5rem 2rem;
    background: ${colorStack.whiteGrey};
`;

const RepliesInfo = styled.div`
    background: ${colorStack.white};
    color: ${colorStack.middleBlue};
    font-size: ${fontSize[13]};
    padding: 1rem 0 1rem 3rem;
`;

const ApprovalContext = createContext<Partial<IApprovalPanelContext>>(undefined);

interface IProps {
    message: ISingleChannelMessage;
    organizationName: string;
    removeMessage(messageId: string);
}

export const ApprovalMessage: FC<IProps> = props => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { search } = useLocation();
    const { currentClusterId, currentUserProfile, updatedMessage } = useSelector<IState, IClusterReducer & IProfileReducer>(state => ({
        ...state.clusters,
        ...state.profile
    }));
    const [showComments, setShowComments] = useState<boolean>(false);
    const [showPanel, setShowPanel] = useState<boolean>(false);
    const [approvalStatus, setApprovalStatus] = useState<EValidationRequestStatus>(undefined);
    const [singleValidation, setSingleValidation] = useState<ValidationResponse>(undefined);
    const updatedRequestId = useMemo(() => { return updatedMessage?.additionalInformation?.RequestId; }, [updatedMessage]);

    const initState: IApprovalPanelContext = {
        showPanel,
        clusterId: currentClusterId,
        validationId: props.message?.additionalInformation?.requestId,
        status: approvalStatus,
        showComments,
        setShowComments,
        setShowPanel,
        setStatus: setApprovalStatus
    };

    const moreHandler = useCallback(() => {
        setShowPanel(true);
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(search);
        if (props.message?.additionalInformation?.requestId === params.get('validationId')) {
            moreHandler();
        }
    }, [search]);

    useEffect(() => {
        if (!showPanel) {
            history.push(`${location.pathname}`);
        }
    }, [showPanel]);

    const getActiveValidator = useCallback((data: ValidationResponse) => {
        const activeStage = data?.stages?.find((elem) => elem.status === EValidationRequestStageStatus.InProgress && elem.validators.find((e) => e.status !== null)) ? data?.stages?.find((elem) => elem.status === EValidationRequestStageStatus.InProgress) : data?.stages?.sort((a, b) => b.stageId - a.stageId).find((elem) => elem.status === EValidationRequestStageStatus.Done);
        const sortedStage = activeStage?.validators?.sort((a, b) => +new Date(b.modifiedAt) - +new Date(a.modifiedAt));
        return {
            activeValidator: sortedStage?.find((e) => e.status !== null),
            activeStage: activeStage?.stageId
        };
    }, []);

    const activeApproverData = useMemo(() => { return getActiveValidator(singleValidation); }, [currentUserProfile, props?.message, updatedRequestId, singleValidation]);

    const requestSingleValidation = useCallback((requestId?: string) => {
        const isSingleApprovalUpdated = [requestId, updatedRequestId].includes(props.message.additionalInformation.requestId);
        isSingleApprovalUpdated ?
            dispatch<GetSingleValidation>(getSingleValidation(currentClusterId, (requestId || updatedRequestId))).then(response => {
                setSingleValidation(response);
            })
            :
            dispatch<GetSingleValidation>(getSingleValidation(currentClusterId, props.message.additionalInformation.requestId)).then(response => {
                setSingleValidation(response);
            });
    }, [props.message, currentClusterId, updatedRequestId]);

    useEffect(() => {
        props.message.additionalInformation.requestId !== updatedRequestId && requestSingleValidation();
    }, [currentClusterId, updatedRequestId, updatedMessage]);

    useEffect(() => {
        props.message.additionalInformation?.requestId === updatedRequestId && requestSingleValidation(updatedRequestId);
    }, [updatedRequestId, updatedMessage]);

    return (
        <>
            <Wrapper>
                <Header>
                    <Left>
                        <StampIco>
                            <ApprovalStamp width={29} />
                        </StampIco>
                        <Title>
                            <FormattedMessage id="approval.message.title" />
                        </Title>
                        <div>
                            <Avatar
                                picture={props.message.sender.picture}
                                firstName={props.message.sender.firstName}
                                lastName={props.message.sender.lastName}
                                id={props.message.sender.id}
                                extended
                            />
                        </div>
                        <DateContainer >{moment.utc(props.message.createdDate).local().format('DD MMM YYYY HH:mm:ss')}</DateContainer >
                    </Left>
                    <Right>
                        <Status status={singleValidation?.status} onClick={moreHandler}>
                            {getStatusText(singleValidation?.status, intl)}
                        </Status>
                    </Right>
                </Header>
                <Content>
                    {singleValidation?.resources?.length > 0 && singleValidation?.resources?.filter(item => item.type === PathType.ClusterDocument)?.length > 0 &&
                        <AttachmentBox>
                            <ScopeName><FormattedMessage id="validation.label.document" values={{ count: singleValidation?.resources?.length }} /></ScopeName>
                            {singleValidation?.resources?.map(file =>
                                <Attachment
                                    key={file.id}
                                    id={file.id}
                                    fileName={file.name}
                                    clusterId={currentClusterId}
                                    customPath={PathType.Documents}
                                    visibleButtons
                                />
                            )}
                        </AttachmentBox>
                    }
                    {activeApproverData?.activeValidator &&
                        <SingleApprover
                            key={activeApproverData?.activeValidator?.principal?.childId}
                            stage={activeApproverData?.activeStage}
                            validator={activeApproverData?.activeValidator}
                            message={singleValidation}
                            currentClusterId={currentClusterId}
                            validationId={props.message.additionalInformation?.requestId}
                        />
                    }
                    <MoreDetails data-lc="js-lc-button-more-details" onClick={moreHandler}>
                        <FormattedMessage id="approval.message.moreDetails" />
                    </MoreDetails>
                </Content>
                <ThreadMessages>
                    <RepliesInfo>{props.message.threadMessages.length} <FormattedMessage id="approval.message.replies"/></RepliesInfo>
                    {props.message.threadMessages.length > 0 &&
                        <Messgaes>
                            {props.message.threadMessages.map(message =>
                                <Fragment key={message.id}>
                                    <SingleMessageHeader
                                        picture={message.sender.picture}
                                        name={`${message.sender.firstName} ${message.sender.lastName}`}
                                        date={message.createdDate}
                                        direction="row"
                                        messaging={message}
                                        messageId={message.id}
                                        removeMessage={props.removeMessage}
                                        clusterId={currentClusterId}
                                        organizationName={props.organizationName}
                                        senderId={message.sender.id}
                                        messageView={false}
                                        icon={faTimes}
                                        enableFullView={false}
                                    />
                                    <SingleMessageContent
                                        key={message.id}
                                        message={message}
                                        clusterId={currentClusterId} />
                                </Fragment>
                            )}
                        </Messgaes>
                    }
                </ThreadMessages>
            </Wrapper>
            <ApprovalContext.Provider value={initState}>
                <Panel
                    context={ApprovalContext}
                    headerContent={<ApprovalPanelHeader context={ApprovalContext} />}
                >
                    <ApprovalPanelContent context={ApprovalContext} />
                </Panel>
            </ApprovalContext.Provider>
        </>
    );
};
