import { faCalendarDays, faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { FC } from 'react';
import styled from 'styled-components';

import { Avatar } from '../Avatar/Avatar';
import { ContextMenu, ISingleLink } from '../ContextMenu/ContextMenu';
import { IUserBrief } from '../../../entities/IGlobal';
import { colorStack } from '../../../styleHelpers/colors';
import { useDropdown } from '../../../tools/hooks';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    > div {
        max-width: 250px;
    }
`;

const DateBox = styled.div`
    color: ${colorStack.label};
    border-left: 1px solid ${colorStack.middleGrey};
    padding-left: 1rem;
    margin-left: 1rem;
    svg {
        margin-right: .5rem;
    }
`;

const MenuBox = styled.div`
    position: relative;
    margin: 0 0 0 1rem;
    cursor: pointer;
    > div {
        right: 0;
    }
`;

interface IProps {
    user?: IUserBrief;
    date?: string | Date;
    menuLinks?: ISingleLink[];
}

export const Meta: FC<IProps> = props => {
    const [wrapperRef, dropdownOpen, toggleDropdown, closeDropdown] = useDropdown();

    const actionHandler = (action) => {
        action();
        closeDropdown();
    };

    return (
        <Wrapper>
            {props.user &&
                <Avatar
                    id={props.user.id}
                    firstName={props.user.firstName}
                    lastName={props.user.lastName}
                    picture={props.user.picture}
                    extended
                />
            }
            {props.date &&
                <DateBox><FontAwesomeIcon icon={faCalendarDays} />{moment(moment.utc(props.date)).local().format('DD MMM YYYY HH:mm:ss')}</DateBox>
            }
            {props.menuLinks &&
                <MenuBox ref={wrapperRef}>
                    <FontAwesomeIcon icon={faEllipsis} onClick={toggleDropdown} />
                    {dropdownOpen &&
                        <ContextMenu
                            links={props.menuLinks.map(elem => ({...elem, action: () => actionHandler(elem.action)}))}
                        />
                    }
                </MenuBox>
            }
        </Wrapper>
    );
};