import styled, { css } from 'styled-components';
import { rgba } from 'polished';

import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { colorStack } from '../../../../../styleHelpers/colors';
import { AllStatusEnums, EStatus, EValidationRequestStatus, EValidatorStatus } from '../../../../../entities/IGlobal';

export const ApprovedInformations = styled.div<{ isJobTitle?: boolean}>`
    display: flex;
    align-items: center;
    font-size: ${fontSize[16]};
    ${props => props.isJobTitle && css`
        margin-top: 5%;
    `}
`;

export const RoleContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 1%;
    align-items: center;
    gap: 3%;
    font-size: ${fontSize[16]};
`;

export const ApprovalContent = styled.div`
    width: 100%;
    margin-top: 1%;
`;

export const OverviewBox = styled.div`
    display: flex;
    padding: 2rem;
    > img {
        width: 280px;
        margin: 0 auto;
        cursor: pointer;
    }
    > a {
        margin: 0 auto;
        img {
            width: 280px;
            cursor: pointer;
        }
    }
`;
export const AttachmentBox = styled.div`
    display: flex;
    padding: 2rem;
    flex-wrap: wrap;
    align-items: center;
`;

export const ScopeName = styled.div`
    color: ${colorStack.content};
    font-size: ${fontSize[16]};
    margin: 0 1rem 0 0px;
`;

export const ApproverBox = styled.div`
    height: 100px;
    padding: 0;
    display: flex;
    align-items: stretch;
`;

export const AvatarBox = styled.div`
    width: 25%;
    border-bottom: 1px solid ${colorStack.ligthGrey};
    display: flex;
    align-items: center;
    align-items: stretch;
    margin: 0 1px 0 0;
    background: ${colorStack.whiteGrey};
    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        :nth-child(3) {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
    }
`;

export const LeftBar = styled.div<{ status: EValidatorStatus | EValidationRequestStatus, square?: boolean }>`
    width: 8px;
    ${props => props.status === EValidatorStatus.WaitingForPreviousStep && css`
        border-color: ${colorStack.disabled};
        background: ${rgba(colorStack.disabled, .8)};
    `}
    ${props => (props.status === EValidatorStatus.InProgress || props.status === EValidationRequestStatus.Active) && css`
        border-color: ${colorStack.middleOrange};
        background: ${rgba(colorStack.middleOrange, .8)};
    `}
    ${props => (props.status === EValidationRequestStatus.Completed || props.status === EValidatorStatus.Approved) && css`
        border-color: ${colorStack.green};
        background: ${rgba(colorStack.green, .8)};
    `}
    ${props => props.status === EValidatorStatus.Rejected && css`
        border-color: ${colorStack.red};
        background: ${rgba(colorStack.red, .8)};
    `}
`;

export const Status = styled.div<{ status: AllStatusEnums, square?: boolean, panel?: boolean}>`
    width: 19%;
    ${props => !props.square && css`
        width: auto;
        border-radius: 20px;
        height: 100%;
    `};
    ${props => props.panel && css`
        height: 100%;
    `};
    padding: 0 .5rem;
    text-align: center;
    border: 1px solid;
    margin: 0 1px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    ${props => props.status === EValidatorStatus.WaitingForPreviousStep && css`
        border-color: ${colorStack.disabled};
        background: ${rgba(colorStack.disabled, .8)};
    `}
    ${props => (props.status === EValidatorStatus.InProgress || props.status === EStatus.Delivered || props.status === EValidationRequestStatus.Active) && css`
        border-color: ${colorStack.middleOrange};
        background: ${rgba(colorStack.middleOrange, .8)};
    `}
    ${props => (props.status === EValidationRequestStatus.Completed || props.status === EValidatorStatus.Approved || props.status === EValidatorStatus.Favorable || props.status === EValidatorStatus.Acknowledged) && css`
        border-color: ${colorStack.green};
        background: ${rgba(colorStack.green, .8)};
        color: ${colorStack.white};
    `}
    ${props => (props.status === EValidatorStatus.Rejected || props.status === EValidatorStatus.Unfavorable) && css`
        border-color: ${colorStack.red};
        background: ${rgba(colorStack.red, .8)};
        color: ${colorStack.white};
    `}
`;

export const ButtonBox = styled.div<{isButtonsView?: boolean}>`
    display: flex;
    align-items: center;
    padding: 0 1rem;
    width: 390px;
    background: ${colorStack.whiteGrey};
    border-bottom: 1px solid ${colorStack.ligthGrey};
    ${props => props.isButtonsView && css`
        justify-content: center;
    `};
    button {
        margin: 0 0 0 1rem;
    }
`;

export const StageNumber = styled.div`
    color: ${colorStack.content};
    font-size: ${fontSize[20]};
    text-align: center;
    width: 54px;
`;

export const Label = styled.div`
    color: ${colorStack.content};
    font-size: ${fontSize[16]};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 25%;
    padding: 0 1rem;
    background: ${colorStack.whiteGrey};
    border-bottom: 1px solid ${colorStack.ligthGrey};
    margin: 0 1px 0 0;
`;

export const YourStatusBox = styled.div`
    padding: 0 1rem;
    margin: 1.9rem 0 1.9rem 0;
`;

export const Title = styled.div`
    font-size: ${fontSize[16]};
    color: ${colorStack.content};
    margin: 0 0 1rem 0;
`;

export const DetailsBox = styled.div`
    background: ${colorStack.white};
    padding-top: 1rem;
`;

export const LegendContainer = styled.div`
    display: flex;
    height: 32px;
    font-weight: 500;
    div {
        border-bottom: 1px solid ${colorStack.ligthGrey};
        background: ${colorStack.whiteGrey};
        margin: 0 1px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
    };
    div:first-child{
        width: 25%;
    };
    div:nth-child(2),div:nth-child(3){
        width: 25%;
        padding: 0 1rem;

    };
    div:nth-child(4) {
        width: 19%;
        padding: 0 0.5rem;
    };
    div:last-child {
        width: 390px;
        padding: 0 1rem;
        margin: 0;
    };
`;