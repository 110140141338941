import { faCalendar, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import DatePicker, { DatePickerProps } from 'react-date-picker/dist/entry.nostyle';
import { DateRangePickerProps } from '@wojtekmaj/react-daterange-picker';
import { Value } from '@wojtekmaj/react-daterange-picker/dist/cjs/shared/types';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import Calendar from 'react-calendar';

import { Lcid } from '../../../entities/ILanguage';
import { IState } from '../../../reducers';
import { IProfileReducer } from '../../../reducers/profileReducer';
import { colorStack } from '../../../styleHelpers/colors';
import { fontSizeAndHeight } from '../../../styleHelpers/fontSizes';
import { headersShadow } from '../../../styleHelpers/mixins/shadow';
import { languageList } from '../../../tools/languageTools';

const Wrapper = styled.div<{isRange: boolean}>`
    font-family: 'Roboto', sans-serif;
    width: 100%;
    ${props => props.isRange && css`
        height: 380px;
    `}
    .react-date-picker__calendar {
        &--closed {
            display: none;
        }
        &--open {
            display: block;
            margin: 3px 0 0 0;
        }
    }
    .react-daterange-picker__calendar {
        &--closed {
            display: none;
        }
        &--open {
            display: block;
            margin: 3px 0 0 0;
        }
    }
    .react-daterange-picker {
        &__range-divider {
            display: none;
        }
    }
    .custom-calendar-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        > div {
            border: none;
            display: flex;
            justify-content: space-between;
            padding: 8px 12px 4px 12px;
            height: 42px;
            > div {
                width: 100%;
                cursor: pointer;
                ${props => !props.isRange && css`
                    opacity: 0;
                `}
                input, span {
                    cursor: pointer;
                }
            }
        }
        .react-date-picker {
            &__wrapper {
                button {
                    cursor: pointer;
                    &:nth-child(2) {
                        margin: 0 1rem 0 auto;
                    }
                }
            }
        }
    }
    .react-calendar {
        background: ${colorStack.white};
        padding: 30px 24px;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        width: 328px;
        z-index: 999;
        border: none;
        position: relative;
        ${headersShadow()};
        &__navigation{
            display: flex;
            padding: 0 0.8rem;
            justify-content: space-between;
            margin: 0 0 1.5rem 0;
            button {
                color: ${colorStack.middleBlue};
                ${fontSizeAndHeight[20]};
                cursor: pointer;
            }
            &__prev2-button {
                margin: 0 2rem 0 0;
            }
            &__next2-button {
                margin: 0 0 0 2rem;
            }
            &__label {
                ${fontSizeAndHeight[13]};
            }
        }
        &__tile {
            width: 40px;
            height: 40px;
            border-radius: 8px;
            ${fontSizeAndHeight[13]};
            color: ${colorStack.content};
            &--active {
                background: ${colorStack.whiteBlue};
                font-weight: 600;
            }
            &--hasActive {
                background: ${colorStack.whiteBlue};
            }
            &--now {
                border: 1px solid ${colorStack.middleBlue};
            }
        }
        &__year-view {
            &__months {
                &__month {
                    ${fontSizeAndHeight[13]};
                }
            }
        }
        &__month-view {
            &__days {
                &__day {
                    cursor: pointer;
                    &--neighboringMonth {
                        color: ${colorStack.disabled};
                    }
                    &:hover {
                        background: ${colorStack.whiteBlue};
                        color: ${colorStack.middleBlue};
                        font-weight: 600;
                    }
                }
            }
            &__weekdays {
                margin: 0 0 0.8rem 0;
                &__weekday {
                    font-weight: 600;
                    ${fontSizeAndHeight[13]};
                    text-align: center;
                    cursor: default;
                    abbr {
                        text-decoration: none;
                    }
                }
            }
        }
    }
`;

type IProps = DatePickerProps & DateRangePickerProps & {
    noIcons?: boolean;
    rangePicker?: boolean;
    onChange(value: Value);
};

export const CustomCalendar: FC<IProps> = props => {
    const { currentUserProfile } = useSelector<IState, IProfileReducer>(state => state.profile);
    return (
        <Wrapper isRange={props.rangePicker}>
            {props.rangePicker ? (
                <Calendar
                    {...props}
                    selectRange
                    data-lc="js-lc-calendarWrapper"
                    className="custom-calendar-wrapper"
                    locale={languageList[currentUserProfile?.preferredLanguage] || languageList[Lcid.Fr]}
                />
            ) : (
                <DatePicker
                    {...props}
                    data-lc="js-lc-calendarWrapper"
                    calendarClassName="custom-calendar"
                    className="custom-calendar-wrapper"
                    calendarIcon={!props.noIcons && <FontAwesomeIcon icon={faCalendar} />}
                    clearIcon={!props.noIcons && <FontAwesomeIcon icon={faXmark} />}
                />
            )}
        </Wrapper>
    );
};