export enum EActivityTypes {
    Text = 'Text',
    TextFile = 'TextFile',
    Attachment = 'Attachment',
    AttachmentFiles = 'AttachmentFiles',
    UploadItems = 'UploadItems',
    CopyItems = 'CopyItems',
    MoveItemsFromCluster = 'MoveItemsFromCluster',
    MoveItemsToCluster = 'MoveItemsToCluster',
    Link = 'Link',
    SystemCreationConversation = 'SystemCreationConversation',
    SystemChangingTitle = 'SystemChangingTitle',
    SystemLeavingConversation = 'SystemLeavingConversation',
    SystemRemovingMessage = 'SystemRemovingMessage',
    SystemCreatingCluster = 'SystemCreatingCluster',
    SystemJoinMember = 'SystemJoinMember',
    SystemAddingMember = 'SystemAddingMember',
    SystemRemovingMember = 'SystemRemovingMember',
    SystemDeclaringOwner = 'SystemDeclaringOwner',
    SystemRevokingOwnerStatus = 'SystemRevokingOwnerStatus',
    SystemDocumentsUploaded = 'SystemDocumentsUploaded',
    SystemDocumentRemoved = 'SystemDocumentRemoved',
    UploadingVirtualFileSystem = 'UploadingVirtualFileSystem',
    UploadingFile = 'UploadingFile',
    UploadingFolder = 'UploadingFolder',
    UploadingMultipleVirtualFileSystem = 'UploadingMultipleVirtualFileSystem',
    UploadingMultipleFiles = 'UploadingMultipleFiles',
    RemovingVirtualFileSystem = 'RemovingVirtualFileSystem',
    RemovingFile = 'RemovingFile',
    RemovingMultipleVirtualFileSystem = 'RemovingMultipleVirtualFileSystem',
    RemovingMultipleFiles = 'RemovingMultipleFiles',
    EditingPresentationTeam = 'EditingPresentationTeam',
    EditingSpecificationTeam = 'EditingSpecificationTeam',
    AddingMemberInInvolvedPartyGroupTeam = 'AddingMemberInInvolvedPartyGroupTeam',
    EditingMemberInInvolvedPartyGroupTeam = 'EditingMemberInInvolvedPartyGroupTeam',
    DeletingMemberFromInvolvedPartyGroupTeam = 'DeletingMemberFromInvolvedPartyGroupTeam',
    AddingNewTimlineItemTeam = 'AddingNewTimlineItemTeam',
    EditingTimelineItemTeam = 'EditingTimelineItemTeam',
    MarkingTimelineItemAsReferenceDateTeam = 'MarkingTimelineItemAsReferenceDateTeam',
    DeletingTimelineItemTeam = 'DeletingTimelineItemTeam',
    EditingGeneralInfoOfLegalStakesTeam = 'EditingGeneralInfoOfLegalStakesTeam',
    AddingNewLegalStakeTeam = 'AddingNewLegalStakeTeam',
    EditingLegalStakeTeam = 'EditingLegalStakeTeam',
    DeletingLegalStakeTeam = 'DeletingLegalStakeTeam',
    AddingNewEconomicStakeTeam = 'AddingNewEconomicStakeTeam',
    EditingEconomicStakeTeam = 'EditingEconomicStakeTeam',
    DeletingEconomicStakeTeam = 'DeletingEconomicStakeTeam',
    UpdatingDynamicStakes = 'UpdatingDynamicStakes',
    ValidationRequestPreview = 'ValidationRequestPreview',
    ValidationStatusChanged = 'ValidationStatusChanged',
    ValidatorStatusChanged = 'ValidatorStatusChanged',
    SetUpSimpleDate = 'SetUpSimpleDate',
    SetUpSimpleDateWithReminder = 'SetUpSimpleDateWithReminder',
    SetUpRecuringDate = 'SetUpRecuringDate',
    SetUpRecuringDateWithReminder = 'SetUpRecuringDateWithReminder',
    DateOfEventSimpleDate = 'DateOfEventSimpleDate',
    DateOfEventSimpleDateWithReminder = 'DateOfEventSimpleDateWithReminder',
    DateOfEventRecuringDate = 'DateOfEventRecuringDate',
    DateOfEventRecuringDateWithReminder = 'DateOfEventRecuringDateWithReminder'
}

export enum EActivityTopics {
    Members = 'Members',
    Cluster = 'Cluster',
    Approval = 'Approval',
    Document = 'Document',
    Question = 'Question',
    Talk = 'Talk',
    Ticket = 'Ticket',
    Campaign = 'Campaign',
    Right = 'Right',
    KeyDate = 'KeyDate',
    Clause = 'Clause'
}