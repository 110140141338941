import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { SuggestionDataItem } from 'react-mentions';

import { colorStack } from '../../../styleHelpers/colors';
import { Avatar } from '../Avatar/Avatar';

const MentionWrapper = styled.div<{ focused: boolean }>`
    display: flex;
    align-items: center;
    white-space: break-spaces;
    flex-wrap: nowrap;
    padding: 0.3rem 0.6rem;
    width: 100%;
    margin-top: 2px;
    ${props => props.focused && css`
        color: ${colorStack.middleBlue};
    `}
`;

interface UserSuggestionDataItem extends SuggestionDataItem {
    picture: string;
}

interface IProps {
    picture: string;
    focused: boolean;
    name: string;
    id: string | number;
}

const MentionSuggestion: FC<IProps> = ({ picture, name, focused, id }) => (
    <MentionWrapper focused={focused}>
        <Avatar
            picture={picture}
            firstName={name}
            lastName=""
            id={String(id)}
            extended
            preventRedirection
            size={{
                width: 32,
                height: 32
            }}
        />
    </MentionWrapper>
);

export const renderMentionSuggestion = (suggestion: UserSuggestionDataItem, search: string, highlightDisplay: React.ReactNode, index: number, focused: boolean) => {
    return (
        <MentionSuggestion focused={focused} picture={suggestion.picture} name={suggestion.display} id={suggestion.id} />
    );
};
