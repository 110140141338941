import React, { FC, useState, useEffect, useCallback, createContext, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { IValidationStatus } from '../../../../../entities/IClusters';
import { getSingleLastValidation } from '../../../../../actions/validationActions';
import { colorStack } from '../../../../../styleHelpers/colors';
import { sectionsShadow } from '../../../../../styleHelpers/mixins/shadow';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { StampIco } from '../../ClusterActivity/Components/ApprovalMessage';
import { Approval } from '../../../../Common/CutomIcons/Approval';
import { Panel } from '../../../../Common/Panel/Panel';
import { ApprovalPanelHeader } from '../../../../Approvals/ApprovalPanelHeader';
import { ApprovalPanelContent } from '../../../../Approvals/ApprovalPanelContent';
import { IApprovalPanelContext, ValidationResponse } from '../../../../../entities/IValidation';
import { EValidationRequestStageStatus, EValidationRequestStatus } from '../../../../../entities/IGlobal';
import { IProfileReducer } from '../../../../../reducers/profileReducer';
import { IState } from '../../../../../reducers';
import { SingleApprover } from '../../ClusterActivity/Components/SingleApprover';
import { LegendContainer } from '../../ClusterActivity/Components/StyledComponents';
import { IApprovalReducer } from '../../../../../reducers/approvalReducer';
import { getActivityChannelMessages } from '../../../../../actions/activityActions';
import { getTeamsWithChannels } from '../../../../../actions/clustersActions';
import { IActivitySearchItem } from '../../../../../entities/Activity/global';

type GetSingleLastValidation = ReturnType<typeof getSingleLastValidation>;
type GetActivityChannelMessages = ReturnType<typeof getActivityChannelMessages>;
type GetTeamsWithChannels = ReturnType<typeof getTeamsWithChannels>;

const Stamp = styled(StampIco)`
    border-radius: 50%;
    background: ${colorStack.ligthGrey};
    width: 28px;
    height: 28px;
    padding: .3rem;
    svg, path {
        fill: ${colorStack.darkBlue};
        stroke: ${colorStack.darkBlue};
        stroke-width: 0.2px;
    }
`;

const ApproverWrapper = styled.div`
    margin-bottom: 1rem;
`;

const Wrapper = styled.div<{ validationStatus: IValidationStatus }>`
    ${sectionsShadow()};
    padding: 0.3rem 1rem;
    display: flex;
    align-items: center;
    border-radius: 4px;
    margin-bottom: 1rem;
    cursor: pointer;
    color: ${colorStack.white};
    font-size: ${fontSize[13]};
    a {
        color: ${colorStack.white};
        text-decoration: underline;
    }
    ${({ validationStatus }) => validationStatus === IValidationStatus.Active && css`
        background-color: ${colorStack.whiteOrange};
        color: ${colorStack.darkOrange};
        a {
            color: ${colorStack.darkOrange};
        }
    `}
    ${({ validationStatus }) => validationStatus === IValidationStatus.Completed && css`
        background-color: ${colorStack.lightGreen};
    `}
    ${({ validationStatus }) => validationStatus === IValidationStatus.Rejected && css`
        background-color: ${colorStack.red};
    `}
`;

export enum ApprovalTypes {
    Other = 'Other',
    ClusterOverview = 'ClusterOverview',
    ClusterDocument = 'ClusterDocument'
}

interface IProps {
    validationStatus: IValidationStatus;
    updateDate: Date;
    currentClusterId: string;
    setOverviewResizeRefresh();
}

const ApprovalContext = createContext<Partial<IApprovalPanelContext>>(undefined);

export const ApprovalMessage: FC<IProps> = ({ validationStatus, updateDate, currentClusterId, setOverviewResizeRefresh }) => {
    const { id } = useParams<{ id: string }>();
    const { currentUserProfile, approvalOverviewInformations } = useSelector<IState, IProfileReducer & IApprovalReducer>(state => ({
        ...state.profile,
        ...state.approval
    }));
    const [showPanel, setShowPanel] = useState<boolean>(false);
    const [showComments, setShowComments] = useState<boolean>(false);
    const [approvalStatus, setApprovalStatus] = useState<EValidationRequestStatus>(undefined);
    const [approvalMessage, setApprovalMessage] = useState<IActivitySearchItem>(undefined);
    const dispatch = useDispatch();
    const intl = useIntl();
    const didMountRef = useRef(false);

    const getActiveValidator = useCallback((data: ValidationResponse) => {
        if (data?.stages) {
            const stages = [...data?.stages];
            const activeStage = stages?.find(
                elem => elem.status === EValidationRequestStageStatus.InProgress && validationStatus !== IValidationStatus.Rejected
            );
            const activeValidatorsInStage = activeStage?.validators?.filter((elem) => !elem.status);
            const currentUserActiveValidators = activeValidatorsInStage?.filter((elem => elem.principal.childId === currentUserProfile.id));
            return {
                currentUserActiveValidators: currentUserActiveValidators,
                activeStage: activeStage?.stageId
            };
        }
        return undefined;
    }, []);

    const activeApproverData = useMemo(() => {
        if (!approvalOverviewInformations) {
            return;
        }
        return getActiveValidator(approvalOverviewInformations);
    }, [currentUserProfile, approvalOverviewInformations]);

    const initState: IApprovalPanelContext = {
        showPanel,
        showComments,
        clusterId: currentClusterId,
        validationId: approvalOverviewInformations?.id,
        status: approvalStatus,
        setShowComments,
        setShowPanel,
        setStatus: setApprovalStatus
    };

    useEffect(() => {
        dispatch<GetSingleLastValidation>(getSingleLastValidation(currentClusterId, ApprovalTypes.ClusterOverview)).then((res) => {
            dispatch<GetTeamsWithChannels>(getTeamsWithChannels(id)).then(response => {
                dispatch<GetActivityChannelMessages>(getActivityChannelMessages(0, 50, response[0].teamChannels?.[0].teamChannelResourceId, response[0].teamChannels?.[0].teamId, response[0].teamChannels?.[0].id, {validationRequestId: res.items[0].id})).then(message => {
                    setApprovalMessage(message.items[0]);
                });
            });
        });
    }, [currentClusterId, showPanel, validationStatus]);

    const onValidationStatusClick = useCallback(() => {
        setShowPanel(state => !state);
    }, []);

    useEffect(() => {
        if (didMountRef.current) {
            setOverviewResizeRefresh();
        } else {
            didMountRef.current = true;
        }
    }, [activeApproverData?.currentUserActiveValidators?.length]);

    return (
        <>
            <Wrapper validationStatus={validationStatus} onClick={onValidationStatusClick}>
                <Stamp>
                    <Approval width={18} />
                </Stamp>
                {validationStatus === IValidationStatus.Active &&
                    <div>
                        <FormattedMessage values={{ date: moment(updateDate || new Date()).format('DD MMM YYYY') }} id="cluster.overviewValidation.active" />
                        <br />
                        <FormattedMessage id="cluster.overviewValidation.active.secondText" />
                    </div>
                }
                {validationStatus === IValidationStatus.Completed &&
                    <span dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({ id: 'cluster.overviewValidation.completed' }, {
                            date: moment(updateDate || new Date()).format('DD MMM YYYY'),
                            span: (...chunks) => `<span>${chunks}</span>`,
                            br: (...chunks) => `<br />`
                        })
                    }} />
                }
                {validationStatus === IValidationStatus.Rejected &&
                    <span dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({ id: 'cluster.overviewValidation.rejected' }, {
                            date: moment(updateDate || new Date()).format('DD MMM YYYY'),
                            span: (...chunks) => `<span>${chunks}</span>`,
                            br: (...chunks) => `<br />`
                        })
                    }} />
                }
            </Wrapper>
            <ApproverWrapper>
                {activeApproverData && activeApproverData?.currentUserActiveValidators?.length > 0 &&
                    <LegendContainer>
                        <div><FormattedMessage id="validation.legend.requestedTo" /></div>
                        <div><FormattedMessage id="validation.legend.role" /></div>
                        <div><FormattedMessage id="validation.legend.type" /></div>
                        <div><FormattedMessage id="validation.legend.status" /></div>
                        <div><FormattedMessage id="validation.legend.approvedBy" /></div>
                    </LegendContainer>
                }
                {activeApproverData && activeApproverData?.currentUserActiveValidators?.map(elem =>
                    <SingleApprover
                        key={elem.principal.childId}
                        stage={activeApproverData.activeStage}
                        isActive
                        validator={elem}
                        message={approvalOverviewInformations}
                        currentClusterId={currentClusterId}
                        validationId={approvalOverviewInformations.id}
                        openPanel={setShowPanel}
                    />
                )}
            </ApproverWrapper>
            <ApprovalContext.Provider value={initState}>
                <Panel
                    context={ApprovalContext}
                    headerContent={<ApprovalPanelHeader context={ApprovalContext} />}
                >
                    <ApprovalPanelContent context={ApprovalContext} message={approvalMessage}/>
                </Panel>
            </ApprovalContext.Provider>
        </>
    );
};
