import styled, { css } from 'styled-components';

import { colorStack } from '../../../styleHelpers/colors';
import { fontSize } from '../../../styleHelpers/fontSizes';

export const DropDownBox = styled.div<{approval?: boolean}>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    margin-right: 3px;
    background: ${colorStack.white};
    ${props => !props.approval && css`
        width: 15%;
    `}
`;

export const DropDownTitle = styled.div`
    color: ${colorStack.content};
    font-size: ${fontSize[16]};
    font-weight: 600;
`;

export const BoxTitle = styled.div`
    color: ${colorStack.content};
    font-size: ${fontSize[20]};
    font-weight: 500;
`;

export const Box = styled.div<{ active: boolean }>`
    width: 10%;
    height: 100%;
    background: ${colorStack.whiteGrey};
    margin-right: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    cursor: pointer;
    position: relative;
    transition: background-color .3s;
    ${props => props.active && css`
        background-color: ${colorStack.lightBlue};
    `}
    right: 0 !important;
`;

export const Line = styled.div<{ active: boolean }>`
    background: ${colorStack.middleGrey};
    height: 4px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: background-color .3s;
    ${props => props.active && css`
        background-color: ${colorStack.blue};
    `}
`;

export const SinglePair = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CommentBox = styled.div`
    margin-right: 3px;
    padding: 0 20px;
    cursor: pointer;
    width: 310px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: ${fontSize[16]};
    font-weight: 500;
    background: ${colorStack.white};
    > span {
        position: relative;
    }
    svg {
        height: 24px;
        margin-right: 10px;
    }
`;