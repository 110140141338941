import React, { FC, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';

import { colorStack } from '../../../../../styleHelpers/colors';
import MessagesMenu from '../../../../Common/MessagesMenu';
import { useDropdown } from '../../../../../tools/hooks';
import { IClusterSingleMessage } from '../../../../../entities/IClusters';
import { Avatar } from '../../../../Common/Avatar/Avatar';
import { fontSize } from '../../../../../styleHelpers/fontSizes';

const Header = styled.div<HeaderProps>`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 13px 0;
    padding: .5rem 15px .5rem 15px;
    font-family: 'Roboto', sans-serif;
    background: ${colorStack.whiteGrey};
    .meta-wrapper {
        display: flex;
        flex-direction: row;
        margin: 0 0 2px 20px;
        align-items: baseline;
        .name {
            color: ${colorStack.darkBlue};
            font-weight: 600;
            margin: 0 .3rem .2rem 0;
        }
        .date {
            font-size: ${fontSize[10]};
            color: ${colorStack.disabled};
            min-width: 80px;
        }
        ${(props: HeaderProps) => props.direction === 'row' && css`
            align-items: center;
            flex-direction: row;
            .name {
                margin: 0 30px 0 0;
            }
        `}
    }
    .image-wrapper {
        width: 40px;
        height: 40px;
        margin: 0 10px 0 0;
    }
    .counter {
        font-weight: 500;
    }
`;

const DropdownWrapper = styled.div`
    margin-left: auto;
    position: relative;
`;

const DropdownButton = styled.button`
    outline: 0;
    cursor: pointer;

    img {
        width: 0.75rem;
    }
`;

const SenderWrapper = styled.div`
    display: flex;
`;

const Date = styled.div`
    color: ${colorStack.content};
    font-size: ${fontSize[13]};
    border-left: 1px solid ${colorStack.ligthGrey};
    padding-left: 1rem;
    margin-left: 1rem;
    min-width:200px;
`;

interface HeaderProps {
    direction: 'row' | 'column';
}

interface ISingleMessageHeaderProps {
    dropdownAlt?: React.ReactNode;
    updatedStakes?: number;
    picture: string;
    name: string;
    date: string;
    organizationName: string;
    clusterId: string;
    direction: 'row' | 'column';
    showDropdown?: boolean;
    messageId: string;
    senderId: string;
    messageView: boolean;
    icon: IconDefinition;
    enableFullView: boolean;
    removeMessage?(messageId: string);
}

interface IThreadMessages {
    messaging: IClusterSingleMessage;
}

export const SingleMessageHeader: FC<ISingleMessageHeaderProps & IThreadMessages> = ({ enableFullView, messaging, icon, picture, name, date, direction, showDropdown, messageId, removeMessage, clusterId, organizationName, senderId, dropdownAlt, updatedStakes, messageView }) => {
    const [wrapperRef, dropdownOpen, toggleDropdown] = useDropdown();
    const intl = useIntl();

    const onRemoveMessage = useCallback(() => {
        removeMessage && removeMessage(messageId);
        toggleDropdown();
    }, [messageId, dropdownOpen]);

    return (
        <Header className="thread-header" direction={direction}>
            <Avatar
                picture={picture}
                firstName={name}
                lastName=""
                id={senderId}
                extended
            />
            <div className="meta-wrapper">
                <SenderWrapper>
                    {updatedStakes &&
                        <span dangerouslySetInnerHTML={{
                            __html: intl.formatMessage({ id: 'cluster.updatedStakes' }, {
                                updatedStakes,
                                span: (...chunks) => `<span class="counter">${chunks}</span>`
                            })
                        }} />
                    }
                </SenderWrapper>
                <Date>{moment.utc(date).local().format('DD MMM YYYY HH:mm:ss')}</Date>
            </div>
            {!messageView &&
                <DropdownWrapper ref={wrapperRef}>
                    {(enableFullView || messaging.canDelete) &&
                        <DropdownButton onClick={toggleDropdown} type="button">
                            <FontAwesomeIcon icon={icon} />
                        </DropdownButton>
                    }
                    {dropdownOpen && (
                        <MessagesMenu
                            shadow
                            messageId={messageId}
                            removeMessage={onRemoveMessage}
                            messaging={messaging}
                            organizationName={organizationName}
                            clusterId={clusterId}
                            messageView={messageView}
                            icon={icon}
                            enableFullView={enableFullView}
                        />
                    )}
                </DropdownWrapper>
            }
            {(dropdownAlt && !showDropdown && !!messaging.canDelete) && (
                <DropdownWrapper>
                    {dropdownAlt}
                </DropdownWrapper>
            )}
        </Header>
    );
};
