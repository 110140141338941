import { EValidationRequestStageStatus, EValidationRequestStatus, EValidatorStatus } from './IGlobal';

export interface ISingleValidator {
    label: {
        name: string;
        description: string;
        photo: string;
        type: number;
        promoted: boolean;
        Key: string;
    };
    modifiedAt: Date;
    modifiedBy: {
        cabinet: string;
        emailContact: string;
        firstName: string;
        id: string;
        jobTitle: string;
        lastName: string;
        picture: string;
    };
    principal: {
        childId: string;
        childName: string;
        picture: string;
        type: 'Personal' | 'Group';
    };
    role: {
        Key: string;
        id: string;
        name: string;
    };
    status: EValidatorStatus;
    validatorType: 'Acknowledgement' | 'Approval' | 'Opinion';
}

export const enum StatusesTypes {
    Opinion = 'Opinion',
    Approved = 'Approved',
    Rejected = 'Rejected',
    Acknowledged = 'Acknowledged',
    Favorable = 'Favorable',
    Unfavorable = 'Unfavorable',
    Approval = 'Approval'
}

export interface IValidationStage {
    stageId: number;
    status: EValidationRequestStageStatus;
    validators: ISingleValidator[];
}

export interface ValidationResponse {
    id: string;
    requestorId: string;
    readyForComplete: boolean;
    resources?: {
        id: string;
        name: string;
        type: string;
    }[];
    context?: {
        type: string;
        referencedItem: {
            name: string;
            organizationId: string;
            id: string;
        }
    }
    stages: IValidationStage[];
    status: EValidationRequestStatus;
    lastModifiedAt?: string;
    createdAt: string;
    snapshot: string;
    subject?: string;
    message?: string;
}
export interface IGetSingleLastValidation {
    count: number;
    items: ValidationResponse[];
    itemsOnPageCount: number;
    page: number;
    size: number;
}

export interface IValidationState {
    isValid: boolean;
    message: string;
}

export interface IFormData {
    message: string;
    subject: string;
    referencedItem: {
        type: string;
        referencedItem: {
            id: string;
            name: string;
            organizationId: string;
        };
    };
    resources: {
        name: string;
        type: number;
        id: string;
    }[];
    stages: {
        stageId: number;
        validators: {
            principal: {
                childId: string;
                childName: string;
                type: number;
                picture: string;
                email: string;
            };
            labelId: string;
            role: {
                id: string,
                name: string,
                key: string
              }
              validatorType: string;
        }[];
    }[];
    step?: number;
    isChecked?: boolean;
    step1Valid?: boolean;
}

export interface ITypesWithStatuses {
    type: string;
    translationKey: string;
    validatorStatuses: {
        translationKey: string;
        status: string;
    }[];
}

export enum ApprovalResourceType {
    Other = 0,
    ClusterOverview = 10,
    ClusterDocument = 20
}

export interface IApprovalPanelContext {
    showPanel: boolean;
    showComments?: boolean;
    clusterId: string;
    validationId: string;
    status: EValidationRequestStatus;
    setShowComments?(state: boolean);
    setShowPanel(state: boolean);
    getTemplatesSettingsAndData?();
    setStatus(state: EValidationRequestStatus);
}