import { getSingleValidation } from '../actions/validationActions';
import { EValidationRequestStatus } from '../entities/IGlobal';
import { ValidationResponse } from '../entities/IValidation';
import { history } from '../history';
import store from '../store';

export const redirectApproval = (clusterId: string, validationId: string) => {
    store.dispatch(getSingleValidation(clusterId, validationId, true) as any).then((res: ValidationResponse) => {
        if (res.resources?.[0]?.type === 'ClusterOverview' && res.status === EValidationRequestStatus.Active) {
            history.push('overview');
        } else {
            history.push(`activity?vId=${validationId}`);
        }
    });
};