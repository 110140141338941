import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { faEye } from '@fortawesome/pro-solid-svg-icons';

import { history } from '../../history';
import { ApprovalContent, Title, DetailsBox, AttachmentBox, LegendContainer } from '../Organization/ClustersV2/ClusterActivity/Components/StyledComponents';
import { ValidationResponse } from '../../entities/IValidation';
import { SingleApprover } from '../Organization/ClustersV2/ClusterActivity/Components/SingleApprover';
import { getSingleValidation, getSnapshotImage } from '../../actions/validationActions';
import { Attachment } from '../Common/Attachment/Attachment';
import { fontSizeAndHeight } from '../../styleHelpers/fontSizes';
import { getProfileById } from '../../actions/profileActions';
import { IFullUserProfile } from '../../entities/IProfile';
import { colorStack } from '../../styleHelpers/colors';
import { IState } from '../../reducers';
import { IClusterReducer } from '../../reducers/clustersReducer';
import { getMessageViewByThreadId, getSingleCluster, getTeamsWithChannels } from '../../actions/clustersActions';
import { EValidationRequestStatus } from '../../entities/IGlobal';
import { Meta } from '../Common/Meta/Meta';
import { Button } from '../Common/Buttons/Button';
import { IApprovalReducer } from '../../reducers/approvalReducer';

type GetSingleValidation = ReturnType<typeof getSingleValidation>;
type GetProfileById = ReturnType<typeof getProfileById>;
type GetSingleCluster = ReturnType<typeof getSingleCluster>;
type GetSnapshotImage = ReturnType<typeof getSnapshotImage>;
type GetMessageViewByThreadId = ReturnType<typeof getMessageViewByThreadId>;
type GetTeamsWithChannels = ReturnType<typeof getTeamsWithChannels>;

const OrganizationAndClusterInfo = styled.div`
    ${fontSizeAndHeight[16]};
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

const SenderContainer = styled.div`
    display: flex;
    align-items: center;
`;

const MessageTitle = styled.div`
    ${fontSizeAndHeight[20]};
    font-weight: 500;
    border-right: 1px solid ${colorStack.middleGrey};
    padding-right: 1rem;
    margin-right: 1rem;
`;

const Subject = styled.div`
    font-weight: 500;
    ${fontSizeAndHeight[16]};
    margin-bottom: 0.5rem;
`;

const Message = styled.div`
    ${fontSizeAndHeight[16]};
`;

const InfoWrapper = styled.div`
    display:flex;
    align-items: center;
    gap: 5%;
`;

const MessageContainer = styled.div`
    width: 70%;
    word-wrap: break-word;
`;

const SnapshotContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 30%;
    font-weight: 700;
    ${fontSizeAndHeight[16]};
    gap: 1rem;
    padding: 1rem;
    border: 2px solid ${colorStack.middleGrey};
    background: ${colorStack.bodyBg};
    border-radius: 8px;
`;

interface IProps {
    clusterId: string;
    validationId: string;
    showPopup: boolean;
    threadId?: string;
    setShowPopup(showPopup: boolean);
    getTemplatesSettingsAndData?();
    setStatus(status: string);
}

export enum PathType {
    ClusterDocument = 'ClusterDocument',
    ClusterOverview = 'ClusterOverview',
    Documents = 'Documents',
    OverviewFiles = 'Overview Files'
}

export const ApprovalDetails: FC<IProps> = props => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const { currentClusterData, approvalOverviewInformations } = useSelector<IState, IClusterReducer & IApprovalReducer>(state => ({
        ...state.clusters,
        ...state.approval
    }));
    const [singleValidation, setSingleValidation] = useState<ValidationResponse>(undefined);
    const [singleValidationReady, setSingleValidationReady] = useState<boolean>(false);
    const [profileInfo, setProfileInfo] = useState<IFullUserProfile>(undefined);
    const [fetchingProfile, setFetchingProfile] = useState<boolean>(false);
    const [snapshotName, setSnapshotName] = useState<string>(undefined);
    const [isSnapshotUnavailable, setIsSnapshotUnavailable] = useState<boolean>(false);
    const InProgres = 'InProgress';
    const OverviewVersions = 'Overview Versions';
    const ClusterDocument = 'ClusterDocument';

    useEffect(() => {
        if (approvalOverviewInformations?.id && props.validationId) {
            setSingleValidation(approvalOverviewInformations);
            props.setStatus(approvalOverviewInformations.status);
        }
    }, [approvalOverviewInformations]);

    useEffect(() => {
        if (singleValidation) {
            setSingleValidationReady(true);
        }
        if (singleValidation?.status !== EValidationRequestStatus.Active && singleValidation && !snapshotName) {
            dispatch<GetSnapshotImage>(getSnapshotImage(props.validationId)).then(res => {
                setSnapshotName(res.name);
                setIsSnapshotUnavailable(false);
            }).catch(() => setIsSnapshotUnavailable(true));
        }
    }, [singleValidation]);

    useEffect(() => {
        let stateUpdatesAllowed = true;
        if (props.validationId) {
            dispatch<GetSingleValidation>(getSingleValidation(props.clusterId, props.validationId)).then(response => {
                if (stateUpdatesAllowed) {
                    setSingleValidation(response);
                    props.setStatus(response.status);
                }
            });
        } else if (props.threadId) {
            dispatch<GetTeamsWithChannels>(getTeamsWithChannels(id)).then(res => {
                dispatch<GetMessageViewByThreadId>(getMessageViewByThreadId(res?.[0].teamChannels?.[0].teamChannelResourceId, res?.[0].teamChannels?.[0].teamId, res?.[0].teamChannels?.[0].id, props.threadId)).then((message) => {
                    dispatch<GetSingleValidation>(getSingleValidation(props.clusterId, message[0].details.validationRequestId)).then(response => {
                        if (stateUpdatesAllowed) {
                            setSingleValidation(response);
                            props.setStatus(response.status);
                        }
                    });
                });
            });
        }
        return () => {
            stateUpdatesAllowed = false;
        };
    }, [props.validationId]);

    useEffect(() => {
        let stateUpdatesAllowed = true;
        if (stateUpdatesAllowed && singleValidationReady && singleValidation.requestorId && !fetchingProfile) {
            setFetchingProfile(true);
            dispatch<GetProfileById>(getProfileById(singleValidation.requestorId))
                .then(res => {
                    if (stateUpdatesAllowed) {
                        setProfileInfo(res);
                    }
                })
                .finally(() => {
                    if (stateUpdatesAllowed) {
                        setFetchingProfile(false);
                    }
                });
        }
        dispatch<GetSingleCluster>(getSingleCluster(props.clusterId));
    }, [singleValidationReady]);

    const onViewRequestClick = () => {
        props.setShowPopup(false);
        history.push(`/orgs/${currentClusterData?.organizationUrlName}/cluster/${props?.clusterId}/overview`);
    };

    return (
        <>
            <OrganizationAndClusterInfo>
                <span>{currentClusterData?.organizationName} {'>'} {currentClusterData?.presentation.name}</span>
            </OrganizationAndClusterInfo>
            <InfoWrapper>
                <MessageContainer>
                    <SenderContainer>
                        <MessageTitle>
                            <FormattedMessage id="approval.message.title" />
                        </MessageTitle>
                        <Meta
                            user={{
                                firstName: profileInfo?.firstName,
                                lastName: profileInfo?.lastName,
                                picture: profileInfo?.picture,
                                jobTitle: profileInfo?.jobTitle,
                                cabinet: profileInfo?.cabinet?.name,
                                id: profileInfo?.id,
                                emailContact: profileInfo?.emailContact,
                                type: 'Personal'
                            }}
                            date={singleValidation?.createdAt}
                        />
                    </SenderContainer>
                    {singleValidation?.subject &&
                        <>
                            <Subject>{singleValidation?.subject}</Subject>
                            <Message>{singleValidation?.message}</Message>
                        </>
                    }
                </MessageContainer>
                {singleValidation?.resources[0]?.type !== ClusterDocument ?
                    <>
                        {(singleValidation?.status !== EValidationRequestStatus.Active && singleValidation && snapshotName) &&
                            <SnapshotContainer>
                                <div><FormattedMessage id="validation.approval.details.overview.label" /></div>
                                <Attachment
                                    key={singleValidation?.id}
                                    fileName={snapshotName}
                                    clusterId={props.clusterId}
                                    visibleButtons
                                    customContextMenuOpen
                                    customPath={OverviewVersions}
                                />
                            </SnapshotContainer>
                        }
                        {((singleValidation?.status === EValidationRequestStatus.Active || isSnapshotUnavailable) && singleValidation) &&
                            <Button
                                ico={faEye}
                                bgColor={colorStack.white}
                                borderColor={colorStack.blue}
                                fontColor={colorStack.black}
                                onClick={onViewRequestClick}
                            >
                                <span><FormattedMessage id="validation.approval.panel.link.button" /></span>
                            </Button>
                        }
                    </>
                    :
                    <>
                        {singleValidation?.resources?.length > 0 && singleValidation?.resources?.filter(item => item.type === PathType.ClusterDocument)?.length > 0 &&
                            <AttachmentBox>
                                {singleValidation?.resources?.map(file =>
                                    <Attachment
                                        key={file.id}
                                        id={file.id}
                                        fileName={file.name}
                                        clusterId={props.clusterId}
                                        customPath={PathType.Documents}
                                        visibleButtons
                                    />
                                )}
                            </AttachmentBox>
                        }
                    </>
                }
            </InfoWrapper>
            <ApprovalContent>
                <DetailsBox>
                    <Title><FormattedMessage id="validation.label.statusDetails" /></Title>
                    <LegendContainer>
                        <div><FormattedMessage id="validation.legend.requestedTo" /></div>
                        <div><FormattedMessage id="validation.legend.role" /></div>
                        <div><FormattedMessage id="validation.legend.type" /></div>
                        <div><FormattedMessage id="validation.legend.status" /></div>
                        <div><FormattedMessage id="validation.legend.approvedBy" /></div>
                    </LegendContainer>
                    {singleValidation?.stages?.map((stage) => (stage?.validators || []).map(validator =>
                        <SingleApprover
                            key={validator?.principal?.childId}
                            stage={stage.stageId}
                            isActive={stage.status === InProgres}
                            validator={validator}
                            message={singleValidation}
                            currentClusterId={props.clusterId}
                            validationId={props.validationId}
                            closePopup={props.setShowPopup}
                            getTemplatesSettingsAndData={props.getTemplatesSettingsAndData}
                        />
                    ))}
                </DetailsBox>
            </ApprovalContent>
        </>
    );
};
