import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { colorStack } from '../../../../../styleHelpers/colors';
import { fontSize } from '../../../../../styleHelpers/fontSizes';
import { IClusterSingleMessage } from '../../../../../entities/IClusters';
import { RenderMentions } from '../../../../Common/MentionInput/RenderMentions';
import { linkify } from '../../../../../tools/string';
import { Attachment } from '../../../../Common/Attachment/Attachment';
import { IState } from '../../../../../reducers';
import { ILanguageReducer } from '../../../../../reducers/languageReducer';
import { activityTranslations } from '../../../../../tools/languageTools';
import { MessageSystemTypes } from '../../../../../entities/Messaging/IMessaging';

const Content = styled.pre`
    font-size: ${fontSize[16]};
    color: ${colorStack.darkBlue};
    padding: 1rem 25px 1rem 65px;
    word-break: break-word;
    white-space: pre-wrap;
    width: 100%;
    &.thread-content {
        margin: 0 0 15px 0;
    }
    a, a:visited {
        color: ${colorStack.darkBlue};
        font-weight: 500;
    }
`;

const DocumentsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 1rem 1rem 65px;
`;

interface IProps {
    message: IClusterSingleMessage;
    clusterId: string;
    className?: string;
}

export const SingleMessageContent: FC<IProps> = ({message, className, clusterId}) => {
    const intl = useIntl();
    const { clusterActivityTranslation } = useSelector<IState, ILanguageReducer>(state => state.language);

    return (
        <>
            {(message?.content?.length > 0) &&
                <Content className={className}>
                    <RenderMentions
                        content={(message?.translationId === 'NotTranslatable' || (message?.translationKey === null && message.type === 'Text')) ? message?.content : activityTranslations(message, clusterActivityTranslation, intl) || message.content && linkify(message.content) || ''}
                    />
                </Content>
            }
            {!(message.type === MessageSystemTypes.RemovingMultipleVirtualFileSystem || message.type === MessageSystemTypes.RemovingVirtualFileSystem) &&
                <>
                    {((message.type === MessageSystemTypes.AttachmentFiles || message.type === MessageSystemTypes.TextFile || message.type === MessageSystemTypes.UploadingMultipleFiles)
                        && message?.additionalInformation?.length > 0) &&
                        <DocumentsWrapper>
                            {(message?.additionalInformation || [])?.map(file =>
                                <Attachment
                                    key={message.id}
                                    id={message.id}
                                    fileName={file.fileName}
                                    clusterId={clusterId}
                                    canRemove={false}
                                    visibleButtons
                                    customPath={file.path}
                                />
                            )}
                        </DocumentsWrapper>
                    }
                    {(message.type === MessageSystemTypes.UploadItems && message?.additionalInformation?.items) &&
                        <DocumentsWrapper>
                            {message?.additionalInformation?.items.map(file =>
                                <Attachment
                                    key={message.id}
                                    id={message.id}
                                    fileName={file.fileName}
                                    clusterId={clusterId}
                                    canRemove={false}
                                    visibleButtons
                                    isFolder={file.Type === 0}
                                    customPath={file.path}
                                />
                            )}
                        </DocumentsWrapper>
                    }
                    {(message.type === MessageSystemTypes.RemovingFile && message?.additionalInformation && message.additionalInformation.isDeletedPermanently === false) &&
                        <DocumentsWrapper>
                            <Attachment
                                id={message.id}
                                fileName={message?.additionalInformation?.fileName}
                                sourceName={message?.additionalInformation?.sourceName}
                                clusterId={clusterId}
                                canRemove={false}
                                visibleButtons
                                customPath={message?.additionalInformation?.path}
                                />
                        </DocumentsWrapper>
                    }
                    {((message.type === MessageSystemTypes.RemovingMultipleFiles) && message?.additionalInformation?.filesNames &&
                    message.additionalInformation.isDeletedPermanently === false) &&
                        <DocumentsWrapper>
                            {(message?.additionalInformation.filesNames.map(file =>
                                <Attachment
                                    key={file.id}
                                    id={message.id}
                                    fileName={file.fileName}
                                    sourceName={file.sourceName}
                                    clusterId={clusterId}
                                    canRemove={false}
                                    visibleButtons
                                    customPath={message?.additionalInformation?.path}
                                />
                            ))}
                        </DocumentsWrapper>
                    }
                </>
            }
        </>
    );
};
