import React, { FC, useContext } from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { faComment } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colorStack } from '../../styleHelpers/colors';
import { Box, BoxTitle, CommentBox, DropDownBox, DropDownTitle, Line } from '../Common/Panel/CommonStyles';
import { IApprovalPanelContext } from '../../entities/IValidation';
import { getStatusColor, getStatusText } from '../../tools/statusHelper';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    height: 75px;
    background: ${colorStack.whiteGrey};
`;
const CommentAndStatusWrapper = styled.div`
    display: flex;
`;
const CustomBoxWidth = styled(Box)`
    width: auto;
`;

const Status = styled.div<{color: string}>`
    ${props => props.color && css`
        color: ${props.color};
    `}
`;

interface IProps {
    context: React.Context<Partial<IApprovalPanelContext>>;
}

export const ApprovalPanelHeader: FC<IProps> = props => {
    const intl = useIntl();
    const panelContext = useContext<Partial<IApprovalPanelContext>>(props.context);

    const onCommentClick = () => {
        panelContext.setShowComments(!panelContext.showComments);
    };

    return (
        <Wrapper>
            <CustomBoxWidth active>
                <BoxTitle><FormattedMessage id="approval.panel.title" /></BoxTitle>
                <Line active />
            </CustomBoxWidth>
            <CommentAndStatusWrapper>
                <DropDownBox approval>
                    <DropDownTitle><FormattedMessage id="approval.panel.status" /></DropDownTitle>
                    <Status color={getStatusColor(panelContext?.status)}>{getStatusText(panelContext?.status, intl)}</Status>
                </DropDownBox>
                {panelContext.showComments !== undefined &&
                    <CommentBox onClick={onCommentClick}>
                        <FontAwesomeIcon icon={faComment} />
                        <BoxTitle><FormattedMessage id="activity.panel.header.discussion" /></BoxTitle>
                    </CommentBox>
                }
            </CommentAndStatusWrapper>
        </Wrapper>
    );
};
